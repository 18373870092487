import { Divider, ListItemIcon } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import React from 'react'


const HelpSearch = () => {
    return (
        <div>
            <h2>Search Page:</h2>
            <p>This page allows you to search for devices using a DSN.</p>
            <Divider />
            <h3>Search By DSN...</h3>
            <p>Put the DSN of the device that you want to search for here.</p>
            <Divider />
            <ListItemIcon><SearchIcon /></ListItemIcon>
            <p>Click here after inputting the DSN of the device you want to search for. Alternatively, you can press &ldquo;Enter&rdquo; on your keyboard.</p>
            <Divider />
            <h3>DSN</h3>
            <p>This is the unique identifier for a device. AC = Sock, OC = Camera, OB = Band</p>
            <Divider />
            <h3>Type</h3>
            <p>The type of the device being displayed.</p>
            <Divider />
            <h3>Associated Child Profile</h3>
            <p>The profile that the displayed device is associated to.</p>
            <Divider />
            <h3>Email</h3>
            <p>Email associated to the account that the displayed device is connected to.</p>
            <Divider />
            <h3>Activated Date</h3>
            <p>The date that the displayed device first communicated with Ayla. This is most likely the first time the device was turned on but the customer.</p>
            <Divider />
            <h3>Registration Date</h3>
            <p>The date that the displayed device is registered to the current account.</p>
            <Divider />
            <h3>Firmware Version</h3>
            <p>Firmware version of the displayed device.</p>
            <Divider />
            <h3>MAC Address</h3>
            <p>Media Access Control Address for the displayed device.</p>
            <Divider />
            <h3>Service User ID</h3>
            <p>Unique service user ID of the account for the displayed device.</p>
            <Divider />
            <h3>Ayla Environment</h3>
            <p>Environment where the displayed device resides. Prod, Prod-EU, Staging or Dev.</p>
            <Divider />
            <h3>Last Online Time</h3>
            <p>Time that the displayed device was last online.</p>
            <Divider />
            <h3>View More Details</h3>
            <p>Click this button to see more information on the displayed device.</p>
        </div>
    )
}

export default HelpSearch
