import * as apiCalls from './lib/apiV1/index'


const mapUserDataToDevices = (devicesToMap) => {
    // This is an inefficient way to make these calls. If a user has 10 devices, it will make 20 calls. Needs to be fixed if this becomes an issue
    return Promise.all(devicesToMap.map(async device => {
        const serviceUserDataPromise = apiCalls.getServiceUserBySUID(device.accountId, device.serviceUserIds[0])
        const multipleServiceUsersDataPromise = apiCalls.getServiceUsers(device.accountId)
        const accountDataPromise = apiCalls.getAccountByAccountKey(device.accountId)
        // Allow both calls to be made before awaiting either of them
        const serviceUserData = await serviceUserDataPromise
        const multipleServiceUsersData = await multipleServiceUsersDataPromise
        const accountData = await accountDataPromise

        const responseData = {
            ...device,
            settingsStatus: device.settingsStatus && JSON.parse(device.settingsStatus),
            serviceUserId: device.serviceUserIds[0],
            account: {
                firstName: accountData.firstName,
                lastName: accountData.lastName,
                email: accountData.email,
                region: accountData.region,
                phoneNumber: accountData.phoneNumber
            },
            userProfile: {
                firstName: serviceUserData ? serviceUserData.firstName : null,
                lastName: serviceUserData ? serviceUserData.lastName : null,
            },
            associatedServiceUsers: multipleServiceUsersData
        }
        return responseData
    }))
}

const mapServiceUserDataToDevices = (devices) => {
    return Promise.all(devices.map(async device => {
        const serviceUserData = await apiCalls.getServiceUserBySUID(device.accountId, device.serviceUserIds[0])
        const responseData = {
            ...device,
            serviceUserId: device.serviceUserIds[0],
            userProfile: {
                firstName: serviceUserData ? serviceUserData.firstName : null,
                lastName: serviceUserData ? serviceUserData.lastName : null
            }
        }
        return responseData
    }))
}

export const getDeviceWithUserInfo = async (dsn) => {
    const deviceData = await apiCalls.getDeviceByDsn(dsn)
    const mappedData = await mapUserDataToDevices([deviceData])
    return mappedData[0]
}

export const getDeviceByDsn = async (dsn) => {
    const deviceData = await apiCalls.getDeviceByDsnSearch(dsn)
    if (!deviceData) {
        return null
    }
    return mapUserDataToDevices(deviceData)
}

export const getUserAssociatedDevices = async (dsn) => {
    const deviceData = await apiCalls.getDeviceByDsn(dsn)
    const devicesData = await apiCalls.getDevices(deviceData.accountId)
    return mapUserDataToDevices(devicesData)
}

export const addServiceUsersToAccountData = async (accountData) => {
        const serviceUsersData = await apiCalls.getServiceUsers(accountData.accountId)
        if (serviceUsersData.length === 0) {
            return accountData
        }
        const accountWithServiceUsersData = {
            serviceUsers: { ...serviceUsersData},
            ...accountData,
        }
        return accountWithServiceUsersData
}

export const addDevicesAndServiceUsersToAccount = async (accountData) => {
    const devicesData = await apiCalls.getDevices(accountData.accountId)
    if (devicesData.length === 0) {
        const accountWithServiceUsersData = await addServiceUsersToAccountData(accountData)
        return accountWithServiceUsersData
    }
    const devicesWithServiceUsersData = await mapServiceUserDataToDevices(devicesData)
    const accountWithServiceUsersAndDevicesData = {
        serviceUsersDevices: { ...devicesWithServiceUsersData },
        ...accountData,
    }

    return accountWithServiceUsersAndDevicesData
}

export const getUserAndAssociatedDevicesByEmail = async (email) => {
    const query = 'email'
    const accountData = await apiCalls.getAccountByParam(query, [email])

    if (!accountData) {
        return null
    }

    return addDevicesAndServiceUsersToAccount(accountData[0])
}

export const getUserAndAssociatedDevicesByName = async (firstName, lastName, query) => {
    let accountData = null
    if (query === 'firstLastName') {
        accountData = await apiCalls.getAccountByParam(query, [firstName, lastName])
    } else if (query === 'firstName') {
        accountData = await apiCalls.getAccountByParam(query, [firstName])
    } else if (query === 'lastName') {
        accountData = await apiCalls.getAccountByParam(query, [lastName])
    }
    if (accountData) {
        return Promise.all(Object.values(accountData).map((userAccount) => {
            return addDevicesAndServiceUsersToAccount(userAccount)
        }))
    }
    return accountData
}

export const getUserAndAssociatedDevicesByAccountId = async (accountId) => {
    const accountData = await apiCalls.getAccountByAccountKey(accountId)
    if (!accountData) {
        return null
    }
    const devicesData = await apiCalls.getDevices(accountId)
    const devicesWithServiceUsersData = await Promise.all(devicesData.map(async device => {
        const serviceUserData = await apiCalls.getServiceUserBySUID(device.accountId, device.serviceUserIds[0])
        const responseData = {
            ...device,
            serviceUserId: device.serviceUserIds[0],
            userProfile: {
                firstName: serviceUserData ? serviceUserData.firstName : null,
                lastName: serviceUserData ? serviceUserData.lastName : null
            }
        }
        return responseData
    }))
    const accountWithServiceUsersAndDevicesData = {
        serviceUsersDevices: { ...devicesWithServiceUsersData },
        ...accountData,
    }
    return accountWithServiceUsersAndDevicesData
}
