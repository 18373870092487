import { Divider } from '@material-ui/core'
import React from 'react'
import "../../DeviceOverview/Device.css"
import '../HelpPage.css'


const HelpAdminEmployee = () => {
    return (
        <div>
            <h2>Admin Employee Page:</h2>
            <p>
                This page contains information about a specific employee. It shows
              the username, email and role of the employee.{' '}
            </p>
            <Divider />
            <h3>Send Password Reset</h3>
            <p>
                Clicking on this button will send a password reset email to the
                employee&rsquo;s displayed email address.
            </p>
            <Divider />
            <h3>Roles</h3>
            <p>Displays the role or roles that the employee has.</p>
            <Divider />
            <h3>Change Role</h3>
            <p>
                After checking the desired role, clicking this button will change
                the employee&rsquo;s role to the one that is checked.
            </p>
            <Divider />
            <h3>Danger Zone (Delete User)</h3>
            <p>
                Clicking &ldquo;Delete User&rdquo; will delete the employee from the system.
                Do not click this unless you are completely sure you want to
                delete the employee.
            </p>
        </div>
    )
}

export default HelpAdminEmployee
