import { create } from 'axios'

import * as URLConfig from "./URLConfig"


let axiosInstance = null

function getAuthToken() {
  const token = JSON.parse(
    sessionStorage.getItem(
      `firebase:authUser:${process.env.REACT_APP_API_KEY}:[DEFAULT]`,
    ),
  ).stsTokenManager.accessToken
  return token
}

function API() {
  if (axiosInstance && axiosInstance.defaults.headers.Authorization) {
    return axiosInstance
  }
  const config = { baseURL: URLConfig.getBackendUrl() }
  const token = getAuthToken()
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` }
  }

  axiosInstance = create(config)
  return axiosInstance
}



export default API
