import { IconButton, Grid, Container, Card } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import React from 'react'

import bg from '../../../assets/images/Background.png'
import PasswordForgetForm from '../../PasswordForget'


const PasswordForgetCard = ({ handleSignInCardShow }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src={bg} className="bg" alt="Landing Background" />
      <div>
        <Card className="cardId">
          <div className="backButton">
            <IconButton onClick={handleSignInCardShow}>
              <ArrowBack />
            </IconButton>
          </div>
          <Container>
            <h1>Password Forget</h1>
            <p>Enter your email to send a password reset email.</p>
            <PasswordForgetForm />
          </Container>
        </Card>
      </div>
    </Grid>
  )
}

PasswordForgetCard.propTypes = {
  handleSignInCardShow: PropTypes.func.isRequired
}

export default PasswordForgetCard
