import React, { useContext, useState } from 'react';
import { Button, Select, InputLabel, FormControl } from '@material-ui/core'
import PropTypes from 'prop-types'
import { migrateSock } from '../../../../api/lib/apiV1';
import TextModal from '../../../common/TextModal/TextModal';
import '../../../common/Layout/Buttons.css';
import { SnackbarContext } from '../../../common/Snackbar'


const SockMigration = ({ currentDevice, migrationTargets }) => {
  const { accountId, deviceId } = currentDevice
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [migrationTarget, setTarget] = useState(false)
  const [migrationTargetName, setTargetName] = useState(false)
  const text = `Warning! This process could take up to 20 minutes to complete.
                Please do not click this button again within that timeframe!
                Before clicking the confirm button, please make sure that the
                device is plugged in.`
  const snackbar = useContext(SnackbarContext)

  const showConvertModal = () => {
    setIsModalOpen(true)
  }

  const hideConvertModal = () => {
    setIsModalOpen(false)
  }

  const handleConvert = async () => {
    const migrationResult = await migrateSock(accountId, deviceId, migrationTarget)

    if (migrationResult) {
      snackbar.openSnackbar( `Migration request sent successfully. Please wait 20 minutes before
         trying again.`
      )
    } else {
      snackbar.openSnackbar(
        'Error sending migration request. Please try again.'
      )
    }

    setIsButtonDisabled(true)
    setIsModalOpen(false)
  }

  const handleTargetChange = e => {
    setTarget(e.target.value)
    setTargetName(e.target.options[e.target.selectedIndex].text)

    if (e.target.value === '') {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(false)
    }
  }

  return (
    <div>
      <nobr>
        <FormControl style={{ maxWidth: 180 }} variant="outlined" margin="dense">
          <InputLabel id="migration-target-label">Sock Type</InputLabel>
          <Select
            native
            value={migrationTarget}
            labelId="migration-target-label"
            label="Sock Type"
            onChange={handleTargetChange}>
              <option aria-label="None" value="" />
              {migrationTargets?.map(t => (<option key={`target_${t.type}`} value={t.type}>{t.name}</option>))}
          </Select>
        </FormControl>
        <Button
    className="emptyBtn emptySmaller"
          style={{ marginLeft: 8, marginTop: 8 }}
          variant="outlined"
          disabled={isButtonDisabled}
          onClick={showConvertModal}>
          Start
        </Button>
      </nobr>
      <TextModal
        open={isModalOpen}
        handleClose={hideConvertModal}
        title={`Convert to ${migrationTargetName}?`}
        text={text}
        toExecute={handleConvert}
      />
    </div>
  )
}

SockMigration.propTypes = {
  authUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.string
    ),
    roles: PropTypes.shape(PropTypes.string),
  }),
  currentDevice: PropTypes.shape({
    accountId: PropTypes.string,
    deviceId: PropTypes.string,
    type: PropTypes.string,
  }),
  migrationTargets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    })
  )
}

SockMigration.defaultProps = {
  authUser: {},
  currentDevice: {},
  migrationTargets: []
}

export default SockMigration;
