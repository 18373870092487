import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { compose } from 'recompose'
import { fetchSignInMethodsForEmail } from 'firebase/auth'

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session'

import Account from './Account'


const AccountContainer = ({ firebase }) => {
  const [account, setAccount] = useState(true)
  const [passwordChange, setPasswordChange] = useState(false)
  const [activeSignInMethods, setActiveSignInMethods] = useState([])
  const [error, setError] = useState(null)
  const SIGN_IN_METHODS = [
    {
      id: 'google.com',
      provider: 'googleProvider',
    },
  ]
  const authUser = useContext(AuthUserContext)

  const handleAccountShow = () => {
    setAccount(true)
    setPasswordChange(false)
  }

  const handleSetPasswordChangeShow = () => {
    setPasswordChange(true)
    setAccount(false)
  }

  const fetchSignInMethods = useCallback(() => {
    fetchSignInMethodsForEmail(firebase.auth, authUser.email)
      .then(
        signInMethodsFromFetch => setActiveSignInMethods(signInMethodsFromFetch),
        setError(null),
      )
      .catch(err => setError({ err }))
  }, [firebase.auth, authUser.email])

  useEffect(() => fetchSignInMethods(), [fetchSignInMethods])

  const onSocialLoginLink = provider => {
    firebase.auth.currentUser
      .linkWithPopup(firebase[provider])
      .then(fetchSignInMethods)
      .catch(err => setError({ err }))
  }

  const onUnlink = providerId => {
    firebase.auth.currentUser
      .unlink(providerId)
      .then(fetchSignInMethods)
      .catch(err => setError({ err }))
  }

  return (
    <Account
      account={account}
      activeSignInMethods={activeSignInMethods}
      authUser={authUser}
      error={error}
      fetchSignInMethods={fetchSignInMethods}
      handleAccountShow={handleAccountShow}
      handleSetPasswordChangeShow={handleSetPasswordChangeShow}
      onSocialLoginLink={onSocialLoginLink}
      onUnlink={onUnlink}
      passwordChange={passwordChange}
      SIGN_IN_METHODS={SIGN_IN_METHODS}
    />
  )
}

AccountContainer.propTypes = {
  firebase: PropTypes.shape({
    auth: PropTypes.shape({
      fetchSignInMethodsForEmail: PropTypes.func,
      currentUser: PropTypes.shape({
        linkWithPopup: PropTypes.func,
        unlink: PropTypes.func,
      })
    }),
    user: PropTypes.func,
    doSignOut: PropTypes.func,
    doPasswordReset: PropTypes.func,
    doPasswordUpdate: PropTypes.func,
    onAuthUserListener: PropTypes.func,
    doSendEmailVerification: PropTypes.func,
    doSignInWithEmailAndPassword: PropTypes.func,
    doCreateUserWithEmailAndPassword: PropTypes.func,
  }).isRequired
}

export { AccountContainer as AccountContainerTest }
export default compose(
  withEmailVerification,
  withAuthorization([]),
)(AccountContainer)
