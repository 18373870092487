import React from 'react'
import ReactDOM from 'react-dom'
import { datadogLogs } from '@datadog/browser-logs'
import { createTheme } from '@material-ui/core/styles'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'

import './index.css'
import App from './components/App'
import Firebase, { FirebaseContext } from './components/Firebase'

datadogLogs.init({
  clientToken: 'pub04a11fa878f1697cce0eef3e93e84aad',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  setLevel: 'error',
})

datadogLogs.addLoggerGlobalContext('service', document.location.hostname)

const theme = createTheme({
  typography: {
    h1: { fontSize: '2.5rem' },
    h2: { fontSize: '1.8rem' },
    h3: { fontSize: '1.7rem', fontWeight: 300 },
    h4: { fontSize: '1.2rem', fontWeight: 300 },
    fontFamily: ['"Source Sans Pro"'],
  },
  palette: {
    primary: {
      white: '#fff',
      main: '#73c7ad',
      lighter: '#9de3cd',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff9e72',
      lighter: '#fcdbc9',
      contrastText: '#fff',
    },
    text: {
      secondary: '#989898',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff',
      },
    },
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BreadcrumbsProvider>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </BreadcrumbsProvider>
  </ThemeProvider>,
  document.getElementById('root'),
)
