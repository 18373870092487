import { Divider } from '@material-ui/core'
import React from 'react'

import "../../DeviceOverview/Device.css"
import '../HelpPage.css'


const HelpDeviceOverview = () => {
    return (
        <div>
            <h2>Device Overview Page:</h2>
            <p>This page is a more in depth look at the current device.</p>
            <Divider />
            <h2>Other Associated Devices:</h2>
            <p>These are other devices that are also on the account of the current associated user.</p>
            <Divider />
            <h3>DSN</h3>
            <p>This is the unique identifier for a device. AC = Sock, OC = Camera, OB = Band</p>
            <Divider />
            <h3>Type</h3>
            <p>The type of the current device.</p>
            <Divider />
            <h3>Associated User</h3>
            <p>The profile that the current device is associated to.</p>
            <Divider />
            <h3>Email</h3>
            <p>Email associated to the account that the current device is connected to.</p>
            <Divider />
            <h3>Assigned To</h3>
            <p>The child profile that the current device is assigned to.</p>
            <Divider />
            <h3>Ayla Environment</h3>
            <p>Environment where the current device resides. Prod, Prod-EU, Staging or Dev.</p>
            <Divider />
            <h3>MAC Address</h3>
            <p>Media Access Control Address for the displayed device.</p>
            <Divider />
            <h3>Activated Date</h3>
            <p>The date that the displayed device first communicated with Ayla. This is most likely the first time the device was turned on but the customer.</p>
            <Divider />
            <h3>Registration Date</h3>
            <p>The date that the displayed device is registered to the current account.</p>
            <Divider />
            <h3>Firmware Version</h3>
            <p>Firmware version of the displayed device.</p>
            <Divider />
            <h3>Device Key</h3>
            <p>Unique identifier of the current device.</p>
            <Divider />
            <h3>Service User ID</h3>
            <p>Unique service user ID of the account for the displayed device.</p>
            <Divider />
            <h3>Last Online Time</h3>
            <p>Time that the displayed device was last online.</p>
        </div>
    )
}

export default HelpDeviceOverview
