import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext } from 'react'
import { compose } from 'recompose'
import { getDeviceWithUserInfo, getUserAssociatedDevices } from '../../api/index'
import { unpairDevice, unregisterDevice } from '../../api/lib/apiV1/index'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session'
import DeviceOverview from './DeviceOverview'
import { SnackbarContext } from '../common/Snackbar'
import permissions from '../../constants/permissions'


const DeviceOverviewContainer = ({ match }) => {
  const [currentDevice, setCurrentDevice] = useState()
  const [isDeviceLoading, setIsDeviceLoading] = useState(true)
  const [userDevices, setUserDevices] = useState()
  const [areUserDevicesLoading, setAreUserDevicesLoading] = useState(true)
  const [selectedDeviceTabId, setSelectedDeviceTabId] = useState(match.params.dsn)
  const [isUnpairModalShown, setIsUnpairModalShown] = useState(false)
  const [isUnregisterModalShown, setIsUnregisterModalShown] = useState(false)
  const snackbar = useContext(SnackbarContext)
  const authUser = useContext(AuthUserContext)

  const handleChange = (event, dsn) => {
    setSelectedDeviceTabId(dsn)
  }

  const showUnpairModal = () => {
    setIsUnpairModalShown(true)
  }

  const hideUnpairModal = () => {
    setIsUnpairModalShown(false)
  }

  const showUnregisterModal = () => {
    setIsUnregisterModalShown(true)
  }

  const hideUnregisterModal = () => {
    setIsUnregisterModalShown(false)
  }

  const getDevice = async (dsn) => {
    setIsDeviceLoading(true)
    const result = await getDeviceWithUserInfo(dsn)
    setCurrentDevice(result)
    setIsDeviceLoading(false)
  }

  const getUserDevices = async (dsn) => {
    setAreUserDevicesLoading(true)
    const devicesResult = await getUserAssociatedDevices(dsn)
    setUserDevices(devicesResult)
    setAreUserDevicesLoading(false)
  }

  const unpairSensor = async (accountId, dsn) => {
    try {
      const unpairResult = await unpairDevice(accountId, dsn)
      setIsUnpairModalShown(false)
      if (unpairResult) {
        snackbar.openSnackbar('Unpaired Successfully. You may now plug the sensor back in.')
      } else {
        snackbar.openSnackbar('Error unpairing. Please try again.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const unregisterDeviceFromUser = async (dsn, customerAccountId) => {
    try {
      const unregisterResult = await unregisterDevice(dsn, customerAccountId)
      if (unregisterResult) {
        snackbar.openSnackbar('Unregistered device Successfully.')
      } else {
        snackbar.openSnackbar('Error unregistering device. Please try again.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getDevice(match.params.dsn)
    getUserDevices(match.params.dsn)
    setSelectedDeviceTabId(match.params.dsn)
  }, [match.params.dsn])

  return (
    <DeviceOverview
      authUser={authUser}
      currentDevice={currentDevice}
      handleChange={handleChange}
      hideUnpairModal={hideUnpairModal}
      hideUnregisterModal={hideUnregisterModal}
      isUnpairModalShown={isUnpairModalShown}
      isUnregisterModalShown={isUnregisterModalShown}
      loading={isDeviceLoading || areUserDevicesLoading}
      selectedDeviceTabId={selectedDeviceTabId}
      showUnpairModal={showUnpairModal}
      showUnregisterModal={showUnregisterModal}
      unpairSensor={unpairSensor}
      unregisterDeviceFromUser={unregisterDeviceFromUser}
      userDevices={userDevices}
    />
  )
}

DeviceOverviewContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      dsn: PropTypes.string
    })
  }).isRequired
}

export { DeviceOverviewContainer as DeviceOverviewContainerTest }

export default compose(
  withEmailVerification,
  withAuthorization([permissions.viewDeviceOverview]),
)(DeviceOverviewContainer)
