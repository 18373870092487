import { Drawer, Button, List } from '@material-ui/core'
import React from 'react'
import HelpSearch from './components/HelpSearch'
import HelpSearchByName from './components/HelpSearchByName'
import HelpDeviceOverview from './components/HelpDeviceOverview'
import HelpUserOverview from './components/HelpUserOverview'
import HelpAdminEmployeeList from './components/HelpAdminEmployeeList'
import HelpAdminEmployee from './components/HelpAdminEmployee'
import HelpAccount from './components/HelpAccount'
import "../common/Layout/Buttons.css"
import * as ROUTES from '../../constants/routes'


export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const sideList = () => (
    <div
      style={{ height: '100%' }}
      role="presentation"
      onMouseLeave={toggleDrawer('right', false)}
      onFocus={toggleDrawer('right', false)}
    >
      <h1>Help</h1>
      <List>
        {window.location.pathname === ROUTES.SEARCH && (
          <HelpSearch />
        )}
        {window.location.pathname === ROUTES.SEARCH_BY_NAME && (
          <HelpSearchByName />
        )}
        {window.location.pathname.includes('accounts/' && 'devices/') && (
          <HelpDeviceOverview />
        )}
        {window.location.pathname.includes('accounts/' && 'serviceUsers/') && (
          <HelpUserOverview />
        )}
        {window.location.pathname === ROUTES.ADMIN && (
          <HelpAdminEmployeeList />
        )}
        {window.location.pathname.includes('admin/') && (
          <HelpAdminEmployee />
        )}
        {window.location.pathname.includes('account') && !window.location.pathname.includes('accounts') && (
          <HelpAccount />
        )}
      </List>
    </div>
  )

  return (
    <div>
      <Button
        className="sideBtn"
        variant="outlined"
        onMouseOver={toggleDrawer('right', true)}
        onFocus={toggleDrawer('right', true)}
      >
        Help
      </Button>
      <Drawer
        anchor="right"
        variant="temporary"
        open={state.right}
      >
        {sideList('right')}
      </Drawer>
    </div>
  )
}
