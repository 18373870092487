import { IconButton, Grid, Container, Card } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import React from 'react'

import bg from '../../../assets/images/Background.png'
import SignUpForm from '../../SignUp'
import '../Landing.css'


const CreateAccountCard = ({ handleLandingCardShow }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src={bg} className="bg" alt="Landing Background" />
      <div>
        <Card className="cardId">
          <div className="backButton">
            <IconButton onClick={handleLandingCardShow}>
              <ArrowBack />
            </IconButton>
          </div>
          <Container>
            <h1>Create Account with Email</h1>
            <p>
              To create an account using Google credentials, go back to the last
              page.
            </p>
            <SignUpForm />
          </Container>
        </Card>
      </div>
    </Grid>
  )
}

CreateAccountCard.propTypes = {
  handleLandingCardShow: PropTypes.func.isRequired
}

export default CreateAccountCard
