import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { compose } from 'recompose'
import { getUserAndAssociatedDevicesByAccountId } from '../../api/index'
import { withAuthorization, withEmailVerification } from '../Session'
import UserOverview from './UserOverview'
import { getServiceUsers, getAccountByAccountKey, getSubscriptionByAccountKey } from '../../api/lib/apiV1'
import * as ROUTES from '../../constants/routes'
import permissions from '../../constants/permissions'


const UserOverviewContainer = ({ match }) => {
    const [currentAccount, setCurrentAccount] = useState()
    const [isAccountLoading, setIsAccountLoading] = useState(true)
    const [userDevices, setUserDevices] = useState()
    const [areUserDevicesLoading, setAreUserDevicesLoading] = useState(true)
    const [associatedProfiles, setAssociatedProfiles] = useState()
    const [areAssociatedProfilesLoading, setAreAssociatedProfilesLoading] = useState(true)
    const [selectedProfileTabId, setSelectedProfileTabId] = useState(match.params.serviceUserId)
    const [selectedDeviceTabId, setSelectedDeviceTabId] = useState(match.params.dsn)
    const [subscriptions, setSubscriptions] = useState()
    const [entitlements, setEntitlements] = useState()
    const history = useHistory()

    const handleDevicesTabChange = (event, dsn) => {
        let { serviceUserId } = match.params
        userDevices.forEach(device => {
            if (device.dsn === dsn) {
                serviceUserId = device.serviceUserId
                setSelectedProfileTabId(serviceUserId)
            }
        });
        setSelectedDeviceTabId(dsn)
        history.replace(ROUTES.USERACCOUNT.replace(':accountId', match.params.accountId).replace(':serviceUserId', serviceUserId).replace(':dsn', dsn))
    }

    const handleProfilesTabChange = (event, serviceUserId) => {
        history.replace(ROUTES.USERACCOUNT.replace(':accountId', match.params.accountId).replace(':serviceUserId', serviceUserId).replace(':dsn', match.params.dsn))
    }

    const getAccount = async (accountId) => {
        const result = await getAccountByAccountKey(accountId)
        setCurrentAccount(result)
        setIsAccountLoading(false)
    }

    const getUserDevices = async (accountId) => {
        const devicesResult = await getUserAndAssociatedDevicesByAccountId(accountId)
        setUserDevices(Object.values(devicesResult.serviceUsersDevices))
        setAreUserDevicesLoading(false)
    }

    const getAssociatedProfiles = async (accountId) => {
        const associatedProfilesResult = await getServiceUsers(accountId)
        setAssociatedProfiles(associatedProfilesResult)
        setAreAssociatedProfilesLoading(false)

    }

    const getSubscription = async (accountId) => {
        const result = await getSubscriptionByAccountKey(accountId)
        if (result) {
            setSubscriptions(result.subscriber.subscriptions)
            setEntitlements(result.subscriber.entitlements)
        }
    }

    useEffect(() => {
        if (isAccountLoading && areUserDevicesLoading) {
            getAccount(match.params.accountId)
            getUserDevices(match.params.accountId)
            getAssociatedProfiles(match.params.accountId)
            getSubscription(match.params.accountId)
        }
        setSelectedProfileTabId(match.params.serviceUserId)
        setSelectedDeviceTabId(match.params.dsn)
    }, [isAccountLoading, areUserDevicesLoading, match])

    return (
        <UserOverview
            currentAccount={currentAccount}
            userDevices={userDevices}
            selectedProfileTabId={selectedProfileTabId}
            selectedDeviceTabId={selectedDeviceTabId}
            handleProfilesTabChange={handleProfilesTabChange}
            handleDevicesTabChange={handleDevicesTabChange}
            isAccountLoading={isAccountLoading}
            areUserDevicesLoading={areUserDevicesLoading}
            associatedProfiles={associatedProfiles}
            areAssociatedProfilesLoading={areAssociatedProfilesLoading}
            match={match}
            subscriptions={subscriptions}
            entitlements={entitlements}
        />
    )
}

UserOverviewContainer.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        isExact: PropTypes.bool,
        params: PropTypes.shape({
            dsn: PropTypes.string,
            accountId: PropTypes.string,
            serviceUserId: PropTypes.string
        })
    }).isRequired
}

export default compose(
    withEmailVerification,
    withAuthorization([permissions.viewUserOverview]),
)(UserOverviewContainer)
