import React from 'react'
import {
  Box,
  Grid,
  Container,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Search as SearchIcon } from '@material-ui/icons'

import '../../index.css'
import '../common/Layout/lists.css'
import '../common/Layout/Buttons.css'
import SearchResults from './components/SearchResults'
import WithLoadingSpinner from '../common/LoadingSpinner/withLoadingSpinner'

const Search = ({
  isLoading,
  searchByDsn,
  inputValues,
  userDevices,
  searchByEmail,
  handleEmailChange,
  completedSearchType,
  handleDevicesChange,
  accountInfoWithDevicesAndServiceUsers,
}) => {
  return (
    <Container>
      <Typography variant="h2" paragraph>
        Search
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          sm={6}
          xs={12}
          component="form"
          autoComplete="off"
          className="searchForm"
          onSubmit={searchByEmail}
          data-testid="emailSearch"
        >
          <FormControl
            fullWidth
            variant="outlined"
            size="medium"
            color="secondary"
          >
            <InputLabel htmlFor="search-account-id" className="text-9xl">
              Email or Account ID
            </InputLabel>
            <OutlinedInput
              labelWidth={130}
              variant="outlined"
              id="search-account-id"
              onChange={handleEmailChange}
              value={inputValues.emailInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton value="email" type="submit" color="secondary">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          component="form"
          autoComplete="off"
          onSubmit={searchByDsn}
          className="searchForm"
          data-testid="dsnSearch"
        >
          <FormControl
            fullWidth
            variant="outlined"
            size="medium"
            color="secondary"
          >
            <InputLabel htmlFor="search-dsn">DSN</InputLabel>
            <OutlinedInput
              labelWidth={30}
              id="search-dsn"
              variant="outlined"
              value={inputValues.DSNInput}
              onChange={handleDevicesChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton value="dsn" type="submit" color="secondary">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box my={3}>
        <SearchResults
          accountInfoWithDevicesAndServiceUsers={
            accountInfoWithDevicesAndServiceUsers
          }
          completedSearchType={completedSearchType}
          loading={isLoading}
          userDevices={userDevices}
        />
      </Box>
    </Container>
  )
}

Search.propTypes = {
  completedSearchType: PropTypes.string,
  userDevices: PropTypes.arrayOf(
    PropTypes.shape({
      registrationDate: PropTypes.string,
      dsn: PropTypes.string,
      type: PropTypes.string,
      macAddress: PropTypes.string,
      deviceId: PropTypes.string,
      lastOnlineTime: PropTypes.string,
      firmwareVersion: PropTypes.string,
      aylaEnvironment: PropTypes.string,
      serviceUser: PropTypes.string,
      userProfile: PropTypes.shape({
        firstName: PropTypes.string,
        lastname: PropTypes.string,
      }),
      account: PropTypes.shape({
        firstName: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ),
  accountInfoWithDevicesAndServiceUsers: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    region: PropTypes.string,
    phoneNumber: PropTypes.string,
    serviceUsersDevices: PropTypes.shape({
      accountId: PropTypes.string,
      activatedDate: PropTypes.string,
      deviceId: PropTypes.string,
      dsn: PropTypes.string,
      firmwareVersion: PropTypes.string,
      name: PropTypes.string,
      registrationDate: PropTypes.string,
      serviceUserId: PropTypes.string,
      type: PropTypes.string,
      userProfile: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
  }),
  searchByDsn: PropTypes.func.isRequired,
  handleDevicesChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  inputValues: PropTypes.shape({
    DSNInput: PropTypes.string,
    emailInput: PropTypes.string,
  }).isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  searchByEmail: PropTypes.func.isRequired,
}

Search.defaultProps = {
  accountInfoWithDevicesAndServiceUsers: null,
  completedSearchType: null,
  userDevices: null,
}

export default WithLoadingSpinner(Search)
