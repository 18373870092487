import * as local from '../constants/local'
import * as beta from '../constants/beta'
import * as dev from '../constants/dev'
import * as prod from '../constants/prod'
import * as prodEU from '../constants/prod-eu'
import * as staging from '../constants/staging'

export function getFrontendUrl() {
  if (window.location.origin.includes('localhost')) return local.REACT_APP_URL
  if (window.location.origin.includes('.dev')) return dev.REACT_APP_URL
  if (
    window.location.origin.includes('omp.owletdata.com') ||
    window.location.origin.includes('omp.v1.owletdata.com')
  )
    return prod.REACT_APP_URL
  if (window.location.origin.includes('.eu')) return prodEU.REACT_APP_URL
  if (window.location.origin.includes('.beta')) return beta.REACT_APP_URL
  if (window.location.origin.includes('.staging')) return staging.REACT_APP_URL
  return dev.REACT_APP_URL
}

export function getBackendUrl() {
  if (process.env.REACT_APP_BACKEND_URL)
    return process.env.REACT_APP_BACKEND_URL
  if (window.location.origin.includes('localhost')) return local.BACKEND_URL
  if (window.location.origin.includes('.dev')) return dev.BACKEND_URL
  if (
    window.location.origin.includes('omp.owletdata.com') ||
    window.location.origin.includes('omp.v1.owletdata.com')
  )
    return prod.BACKEND_URL
  if (window.location.origin.includes('.eu')) return prodEU.BACKEND_URL
  if (window.location.origin.includes('.beta')) return beta.BACKEND_URL
  if (window.location.origin.includes('.staging')) return staging.BACKEND_URL
  return dev.BACKEND_URL
}

export function getFirebaseEnvironmentUrl() {
  if (window.location.origin.includes('localhost')) return dev.FIREBASE_URL
  if (window.location.origin.includes('.dev')) return dev.FIREBASE_URL
  if (
    window.location.origin.includes('omp.owletdata.com') ||
    window.location.origin.includes('omp.v1.owletdata.com')
  )
    return prod.FIREBASE_URL
  if (window.location.origin.includes('.eu')) return prodEU.FIREBASE_URL
  if (window.location.origin.includes('.beta')) return beta.FIREBASE_URL
  if (window.location.origin.includes('.staging')) return staging.FIREBASE_URL
  return dev.FIREBASE_URL
}
