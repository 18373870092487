import PropTypes from 'prop-types'
import React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Table, TableBody, TableRow, TableCell, Paper } from '@material-ui/core'
import * as ROUTES from '../../constants/routes'
import WithLoadingSpinner from '../common/LoadingSpinner/withLoadingSpinner'
import AssociatedDevices from '../DeviceOverview/components/AssociatedDevices'
import AssociatedProfiles from './components/AssociatedProfiles'
import '../../index.css'
import '../DeviceOverview/Device.css'
import '../common/Layout/lists.css'


const UserOverview = ({
    currentAccount,
    userDevices,
    entitlements,
    subscriptions,
    associatedProfiles,
    selectedProfileTabId,
    isAccountLoading,
    areUserDevicesLoading,
    areAssociatedProfilesLoading,
    handleDevicesTabChange,
    handleProfilesTabChange,
    selectedDeviceTabId,
 }) => {
    if (isAccountLoading) {
        return null
    }

    const selectedEntitlement = Object.keys(entitlements).length > 0 ? entitlements[Object.keys(entitlements)[0]] : {};
    const selectedsubscription = Object.keys(subscriptions).length > 0 ? subscriptions[Object.keys(subscriptions)[0]] : {};

    const purchaseDate = Object.entries(selectedEntitlement).length > 0 ? selectedEntitlement.purchase_date.split('T')[0] : "";
    const store = Object.entries(selectedsubscription).length > 0 ? selectedsubscription.store : ""
    const productId = Object.entries(selectedEntitlement).length > 0 ? selectedEntitlement.product_identifier : "";
    const periodType = Object.entries(selectedsubscription).length > 0 ? selectedsubscription.period_type : ""
    const expireDate = Object.entries(selectedEntitlement).length > 0 ? selectedEntitlement.expires_date.split('T')[0] : "";

    return (
        <div>
            <BreadcrumbsItem to={ROUTES.USERACCOUNT}>
                {currentAccount.firstName}
            </BreadcrumbsItem>
            <Table className="deviceTable">
                <TableBody>
                    <TableRow>
                        <TableCell className="deviceCell">
                            <h1>User Overview</h1>
                        </TableCell>
                        <TableCell className="deviceCell associatedCells">
                            <h2>Associated Profiles</h2>
                        </TableCell>
                        <TableCell className="deviceCell associatedCells">
                            <h2>Associated Devices</h2>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="deviceCell">
                            <Paper>
                                <dl className="defList">
                                    <dt>User Name</dt>
                                    <dd>{currentAccount.firstName} {currentAccount.lastName}</dd>

                                    <dt>Email Address</dt>
                                    <dd>{currentAccount.email}</dd>

                                    <dt>Phone Number</dt>
                                    <dd>{currentAccount.phoneNumber}</dd>

                                    <dt>Account ID</dt>
                                    <dd>{currentAccount.accountId}</dd>

                                    <dt>Region</dt>
                                    <dd>{currentAccount.region}</dd>
                                </dl>
                            </Paper>
                        </TableCell>
                        <TableCell sm={6} className="deviceCell">
                            <AssociatedProfiles handleChange={handleProfilesTabChange} selectedProfileTabId={selectedProfileTabId} areAssociatedProfilesLoading={areAssociatedProfilesLoading} associatedProfiles={associatedProfiles} />
                        </TableCell>
                        <TableCell sm={6} className="deviceCell">
                            <AssociatedDevices
                                handleChange={handleDevicesTabChange}
                                selectedDeviceTabId={selectedDeviceTabId}
                                areUserDevicesLoading={areUserDevicesLoading}
                                userDevices={userDevices}
                            />
                        </TableCell>
                    </TableRow>
                    </TableBody>
            </Table>
            <Table className="deviceTable">
                <TableBody>
                    <TableRow>
                        <TableCell className="deviceCell">
                           {Object.keys(entitlements).length > 0 ?
                                <h1>Subscription Summary</h1>
                                : <h1>No Subscription</h1>
                            }
                        </TableCell>
                    </TableRow>
                    <TableBody>
                        <TableRow>
                            <Paper>
                                <dl className="defList">
                                    { Object.entries(selectedEntitlement).length > 0 ?
                                        <>
                                            <dt>Purchase Date</dt><dd>{purchaseDate}</dd>
                                            <dt>Store</dt><dd>{store}</dd>
                                            <dt>Product Identifier</dt><dd>{productId}</dd>
                                            <dt>Period Type</dt><dd>{periodType}</dd>
                                            <dt>Expiration Date</dt><dd>{expireDate}</dd>
                                        </>

                                        : <><dt>Not</dt><dd>Available</dd></>
                                    }
                                </dl>
                            </Paper>
                        </TableRow>
                    </TableBody>
                </TableBody>
            </Table>
            <Table className="deviceTable">
                <TableBody>
                    <TableRow>
                        <TableCell className="deviceCell">
                            {Object.keys(entitlements).length > 0 ?
                                <h1>Entitlement: {Object.keys(entitlements)[0]}</h1>
                                : <h1>No Entitlements</h1>
                            }
                        </TableCell>
                        <TableCell className="deviceCell">
                            {Object.keys(subscriptions).length > 0 ?
                                    <h1>Subscription: {Object.keys(subscriptions)[0]}</h1>
                                    : <h1>No Subscriptions</h1>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="deviceCell">
                            <Paper>
                                <dl className="defList">
                                    { Object.entries(selectedEntitlement).length > 0 ?
                                        Object.entries(selectedEntitlement).map(([key, value]) =>
                                        (
                                            <><dt>{key}</dt><dd>{value}</dd></>
                                        ))
                                        : <><dt>Not</dt><dd>Available</dd></>
                                    }
                                </dl>
                            </Paper>
                        </TableCell>
                        <TableCell className="deviceCell">
                            <Paper>
                                <dl className="defList">
                                    { Object.entries(selectedsubscription).length > 0 ?
                                        Object.entries(selectedsubscription).map(([key, value]) =>
                                        (
                                            <><dt>{key}</dt><dd>{value}</dd></>
                                        ))
                                        : <><dt>Not</dt><dd>Available</dd></>
                                    }
                                </dl>
                            </Paper>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}

UserOverview.propTypes = {
    currentAccount: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        accountId: PropTypes.string,
        region: PropTypes.string
    }),
    userDevices: PropTypes.arrayOf(
        PropTypes.shape({
            activatedDate: PropTypes.string,
            dsn: PropTypes.string,
            type: PropTypes.string,
            macAddress: PropTypes.string,
            deviceKey: PropTypes.string,
            registrationDate: PropTypes.string,
            lastOnlineTime: PropTypes.string,
            firmwareVersion: PropTypes.string,
            aylaEnvironment: PropTypes.string,
            serviceUser: PropTypes.string
        })
    ),
    entitlements: PropTypes.arrayOf({}),
    subscriptions: PropTypes.arrayOf({}),
    areUserDevicesLoading: PropTypes.bool.isRequired,
    selectedDeviceTabId: PropTypes.string.isRequired,
    associatedProfiles: PropTypes.arrayOf({}),
    selectedProfileTabId: PropTypes.string.isRequired,
    isAccountLoading: PropTypes.bool.isRequired,
    areAssociatedProfilesLoading: PropTypes.bool.isRequired,
    handleDevicesTabChange: PropTypes.func.isRequired,
    handleProfilesTabChange: PropTypes.func.isRequired,
}

UserOverview.defaultProps = {
    currentAccount: {},
    userDevices: [],
    entitlements:{},
    subscriptions: {},
    associatedProfiles: [],
}

export default WithLoadingSpinner(UserOverview)
