import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react'
import PropTypes from 'prop-types'

import { useFirebase } from '../../Firebase'
import { useSnackbar } from '../../common/Snackbar'

const RolesContext = createContext()

export default function Provider({ children }) {
  const firebase = useFirebase()
  const { openSnackbar } = useSnackbar()
  const [roles, setRoles] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState(null)

  const load = useCallback(
    async function load() {
      const dbRoles = await firebase.getRoles()
      const dbPerms = await firebase.getPermissions()
      setRoles(dbRoles)
      setPermissions(dbPerms)
    },
    [firebase],
  )

  useEffect(() => {
    if (loaded) return
    ;(async function intialLoad() {
      setLoading(true)
      await load()
      setLoaded(true)
      setLoading(false)
    })()
  }, [load, loaded])

  async function updateRole(rid, pid, state) {
    await firebase.updateRole(rid, pid, state)
    await load()
  }

  async function addRole(title) {
    const added = await firebase.addRole(title).catch((e) => {
      openSnackbar(e.message, null, 'error')
      return false
    })
    if (added) openSnackbar(`${title} added`, null, 'success')
    if (!added) return false
    return load()
  }

  async function deleteRole(rid) {
    const deleted = await firebase
      .deleteRole(rid)
      .catch((e) => openSnackbar(e.message, null, 'error'))
    if (deleted) openSnackbar(`${rid} was removed`, null, 'success')
    if (!deleted) return
    await load()
  }

  return (
    <RolesContext.Provider
      value={{ roles, permissions, updateRole, loading, addRole, deleteRole }}
    >
      {children}
    </RolesContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useRoles = () => useContext(RolesContext)
