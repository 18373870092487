import {
  Box,
  List,
  AppBar,
  Hidden,
  Drawer,
  Divider,
  Toolbar,
  ListItem,
  IconButton,
  makeStyles,
  Typography,
  CssBaseline,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  SwipeableDrawer,
} from '@material-ui/core'
import {
  Search,
  People,
  PanTool,
  ChevronLeft,
  RecentActors,
  Menu as HamburgerMenu,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import SignOut from '../../SignOut'
import { AuthUserContext } from '../../Session'
import * as ROUTES from '../../../constants/routes'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  owl: { width: 35, height: 35 },
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.lighter,
  },
  mainToolbar: { justifyContent: 'space-between' },
  drawerPaper: { width: drawerWidth },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'auto',
    position: 'relative',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
}))

const DrawerContents = ({ authUser, header, clickCallback }) => {
  const classes = useStyles()
  return (
    <>
      <Toolbar className={classes.toolbar}>{header}</Toolbar>
      <Divider />
      <List>
        {authUser.permissions.includes('viewSearch') && (
          <ListItem
            component={Link}
            to={ROUTES.SEARCH}
            button
            onClick={clickCallback}
            data-testid="searchLink"
          >
            <ListItemIcon>
              <Search className="navIcon" />
            </ListItemIcon>
            <ListItemText primary="Search" />
          </ListItem>
        )}
        {authUser.permissions.includes('viewSearch') && (
          <ListItem
            button
            component={Link}
            onClick={clickCallback}
            to={ROUTES.SEARCH_BY_NAME}
            data-testid="nameSearchLink"
          >
            <ListItemIcon>
              <RecentActors className="navIcon" />
            </ListItemIcon>
            <ListItemText primary="Search By Name" />
          </ListItem>
        )}
        {authUser.permissions.includes('viewOMPManagement') && (
          <>
            <ListItem
              button
              component={Link}
              to={ROUTES.ADMIN}
              onClick={clickCallback}
              data-testid="employeeManagementLink"
            >
              <ListItemIcon>
                <People className="navIcon" />
              </ListItemIcon>
              <ListItemText primary="Employees" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={ROUTES.ADMIN_ROLES}
              onClick={clickCallback}
              data-testid="roleManagementLink"
            >
              <ListItemIcon>
                <PanTool className="navIcon" />
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItem>
          </>
        )}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader data-testid="userDetails">
            {authUser.firstName} {authUser.lastName}
          </ListSubheader>
        }
      >
        <ListItem
          component={Link}
          to={ROUTES.ACCOUNT}
          button
          onClick={clickCallback}
        >
          <ListItemText>Account</ListItemText>
        </ListItem>
        <ListItem onClick={clickCallback}>
          <SignOut />
        </ListItem>
      </List>
    </>
  )
}

const Layout = ({ children }) => {
  const authUser = useContext(AuthUserContext)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const classes = useStyles()

  const handleClose = () => {
    setDrawerOpen(false)
  }

  const handleOpen = () => {
    setDrawerOpen(true)
  }

  return (
    <>
      <CssBaseline />
      {authUser ? (
        <>
          <AppBar position="sticky" className={classes.appbar}>
            <Toolbar className={classes.mainToolbar}>
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  data-testid="drawerOpen"
                  onClick={handleOpen}
                >
                  <HamburgerMenu />
                </IconButton>
              </Hidden>
              <Hidden smUp>
                <Typography variant="h1" className="siteName">
                  OMP
                </Typography>
              </Hidden>
              <Hidden xsDown>
                <Typography variant="h1" className="siteName">
                  Owlet Management Portal
                </Typography>
              </Hidden>
              <Logo className={classes.owl} fill="white" />
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer}>
            <Hidden smDown>
              <Drawer
                open
                variant="permanent"
                classes={{ paper: classes.drawerPaper }}
              >
                <DrawerContents
                  authUser={authUser}
                  clickCallback={handleClose}
                />
              </Drawer>
            </Hidden>
            <Hidden mdUp>
              <SwipeableDrawer
                open={drawerOpen}
                variant="temporary"
                onOpen={handleOpen}
                onClose={handleClose}
                classes={{ paper: classes.drawerPaper }}
              >
                <DrawerContents
                  authUser={authUser}
                  clickCallback={handleClose}
                  header={
                    <IconButton onClick={handleClose} data-testid="drawerClose">
                      <ChevronLeft />
                    </IconButton>
                  }
                />
              </SwipeableDrawer>
            </Hidden>
          </nav>
          <Box component="main" className={classes.content}>
            {children}
          </Box>
        </>
      ) : (
        children
      )}
    </>
  )
}

const userProp = PropTypes.shape({
  permissions: PropTypes.arrayOf(PropTypes.string),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
})

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  authUser: userProp,
}

Layout.defaultProps = {
  authUser: {
    permissions: [],
    lastName: '',
    firstName: 'NotLoggedIn',
  },
}

DrawerContents.propTypes = {
  authUser: userProp.isRequired,
  clickCallback: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

DrawerContents.defaultProps = {
  header: null,
}

export default Layout
