import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  List,
  Paper,
  Dialog,
  Hidden,
  Avatar,
  Button,
  ListItem,
  Checkbox,
  TextField,
  IconButton,
  makeStyles,
  Typography,
  DialogTitle,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  DialogActions,
  ListItemAvatar,
  DialogContentText,
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { Create as CreateIcon } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CircularProgress from '@material-ui/core/CircularProgress'

import { withFirebase } from '../../Firebase'
import { useEmployee } from '../contexts/EmployeeContext'

const useStyles = makeStyles((theme) => ({
  roleList: {
    maxHeight: 500,
    position: 'relative',
    overflow: 'auto',
    padding: 0,
  },
  danger: {
    backgroundColor: theme.palette.secondary.lighter,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  saveButton: {
    padding: 0,
    width: 100,
  },
}))

function EmployeeUserItem() {
  const theme = useTheme()
  const classes = useStyles()
  const { id: employeeUserId } = useParams()
  const [editingUser, setEditingUser] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const {
    user,
    roles,
    setUid,
    loading,
    setUser,
    deleteUser,
    setSaveUser,
    rolesUpdated,
    setSaveRoles,
    triggerPasswordReset,
  } = useEmployee(employeeUserId)

  useEffect(() => {
    setUid?.(employeeUserId)
  }, [employeeUserId, setUid])

  const toggleRole = (v) => () => {
    const i = user.roles.indexOf(v)
    const newRoles = [...user.roles]

    if (i === -1) newRoles.push(v)
    if (i > -1) newRoles.splice(i, 1)

    setUser({ ...user, roles: newRoles })
  }

  const handleFirstNameChange = (e) => {
    setUser({ ...user, firstName: e.target.value })
  }

  const handleLastNameChange = (e) => {
    setUser({ ...user, lastName: e.target.value })
  }

  const handleSaveUser = () => {
    setSaveUser(true)
    setEditingUser(false)
  }

  if (loading)
    return <CircularProgress className="loadSpin" data-testid="loadingState" />

  return (
    <>
      <Box mx={3} mb={3}>
        <Paper component={List}>
          <Box m={2} component={ListItem} alignItems="flex-start">
            <Hidden xsDown>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
            </Hidden>
            <Box mx={1} component={ListItemText}>
              <Typography variant="h3">
                {editingUser ? (
                  <Grid container spacing={2} data-testid="changeUserNameState">
                    <Grid
                      item
                      autoFocus
                      size="small"
                      label="First Name"
                      component={TextField}
                      value={user?.firstName}
                      onChange={(e) => handleFirstNameChange(e)}
                      InputLabelProps={{ className: classes.label }}
                      inputProps={{ 'data-testid': 'firstNameInput' }}
                    />
                    <Grid
                      item
                      size="small"
                      label="Last Name"
                      component={TextField}
                      value={user?.lastName}
                      onChange={(e) => handleLastNameChange(e)}
                      InputLabelProps={{ className: classes.label }}
                      inputProps={{ 'data-testid': 'lastNameInput' }}
                    />
                    <Grid
                      item
                      color="primary"
                      component={Button}
                      className={classes.saveButton}
                      onClick={() => handleSaveUser()}
                      data-testid="doneChangeUserNameButton"
                    >
                      Save
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {user?.firstName} {user?.lastName}{' '}
                    <IconButton
                      component="span"
                      data-testid="changeUserNameButton"
                      onClick={() => setEditingUser(!editingUser)}
                    >
                      <CreateIcon />
                    </IconButton>
                  </>
                )}
              </Typography>
              <Box my={1}>
                <Typography display="inline" variant="body2">
                  Email:{' '}
                </Typography>
                <Typography
                  display="inline"
                  variant="body2"
                  color="textSecondary"
                >
                  {user?.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mx={3}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Paper component={List} dense className={classes.roleList}>
              <Typography component={ListSubheader} variant="h3">
                Roles
              </Typography>
              {roles?.map((role) => {
                const labelId = `checkbox-list-role-label-${role.rid}`
                return (
                  <ListItem
                    button
                    key={role.rid}
                    onClick={toggleRole(role.rid)}
                  >
                    <ListItemText id={labelId} primary={role.title} />
                    <ListItemIcon>
                      <Checkbox
                        edge="end"
                        checked={!!user?.roles.includes(role.rid)}
                        inputProps={{
                          'aria-labelledby': labelId,
                          'data-testid': `checkbox-${role.rid}`,
                        }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )
              })}
            </Paper>
            <Box my={1}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={!rolesUpdated}
                data-testid="saveRolesButton"
                onClick={() => setSaveRoles(true)}
              >
                Save Roles
              </Button>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button
              fullWidth
              type="button"
              color="primary"
              variant="contained"
              data-testid="resetPasswordButton"
              onClick={() => triggerPasswordReset()}
            >
              Send Password Reset
            </Button>
            <Box my={3}>
              <Button
                fullWidth
                type="button"
                color="primary"
                variant="contained"
                onClick={() => setEditingUser(true)}
              >
                Change Employee Info
              </Button>
            </Box>
            <Box component={Paper} my={3} p={3} className={classes.danger}>
              <Box component={Typography} align="center" variant="h5" mb={1}>
                Danger Zone!
              </Box>
              <Box component={Typography} align="center" mb={3}>
                Don’t Delete Unless You Are Absolutely Sure!
              </Box>
              <Typography align="center">
                <Button
                  color="secondary"
                  variant="contained"
                  data-testid="confirmDeleteUserButton"
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete User
                </Button>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={!!confirmDelete}
        aria-labelledby="confirm-dialog-title"
      >
        <Box component={DialogTitle} p={3} id="confirm-dialog-title">
          Are you sure you want to delete this user?
        </Box>
        <Box component={DialogContentText} px={3}>
          After the user is deleted, you cannot bring them back!
        </Box>
        <Box component={DialogActions} m={3}>
          <Button
            autoFocus
            color="secondary"
            variant="contained"
            onClick={deleteUser}
            data-testid="deleteUserButton"
          >
            Continue Deleting User
          </Button>
          <Button
            autoFocus
            color="primary"
            data-testid="cancelDeleteUserButton"
            onClick={() => setConfirmDelete(false)}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  )
}

export default withFirebase(EmployeeUserItem)
