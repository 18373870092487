import {
  browserSessionPersistence,
  GoogleAuthProvider,
  setPersistence,
} from 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/auth'

import * as URLConfig from '../../api/URLConfig'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: URLConfig.getFirebaseEnvironmentUrl(),
  projectId: process.env.REACT_APP_PROJECT_ID,
}

class Firebase {
  constructor() {
    firebase.initializeApp(config)

    /* Firebase APIs */

    this.auth = firebase.auth()
    this.db = firebase.database()
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password).catch((error) => {
      console.error(`Error signing in with email and password: ${error}`)
    })

  doSignInWithGoogle = () =>
    setPersistence(this.auth, browserSessionPersistence)
      .then(() => {
        const provider = new GoogleAuthProvider()
        return firebase.auth().signInWithPopup(provider)
      })
      .catch((error) => {
        // Handle Errors here.
        console.error(`Error signing in with Google: ${error}`)
      })

  doSignOut = () => this.auth.signOut()

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      // Change the url
      url: URLConfig.getFrontendUrl(),
    })

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      let authUserItem = authUser

      if (authUserItem) {
        this.user(authUserItem.uid)
          .once('value')
          .then(async (snapshot) => {
            const dbUser = snapshot.val()

            if (!dbUser.roles) {
              dbUser.roles = {}
            }
            const claimedRoles = Object.keys(dbUser.roles)

            // merge auth and db user
            authUserItem = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              lastLoginDate: authUser.metadata.lastSignInTime,
              permissions: [],
              ...dbUser,
            }

            const permissionPromises = claimedRoles.map(async (role) => {
              if (await this.hasRole(role, authUser.uid)) {
                const permissions = await this.rolePermissions(role)
                authUserItem.permissions =
                  authUserItem.permissions.concat(permissions)
                return permissions
              }
              return []
            })

            // Wait for all permission promises to resolve
            await Promise.all(permissionPromises)
            authUserItem.permissions = [...new Set(authUserItem.permissions)]

            next(authUserItem)
          })
      } else {
        fallback()
      }
    })

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`)

  users = () => this.db.ref('users')

  // *** Role API ***

  hasRole = async (rid, uid) => {
    const snap = await this.db.ref(`roles/${rid}/users/${uid}`).once('value')
    return !!snap.val()
  }

  role = (rid) => this.db.ref(`roles/${rid}`)

  rolePermissions = async (rid) => {
    const perms = await this.db.ref(`roles/${rid}/permissions`).once('value')
    return Object.keys(perms.val())
  }

  roles = () => this.db.ref('roles')

  // *** Permission API ***

  permission = (pid) => this.db.ref(`permissions/${pid}`)

  permissions = () => this.db.ref('permissions')

  async getRoles() {
    const dbRoles = (await this.db.ref('roles').once('value')).val()
    const roles = Object.values(dbRoles).map((r) => ({
      ...r,
      users: Object.keys(r.users || {}),
      permissions: Object.keys(r.permissions || {}),
    }))
    return roles
  }

  async getPermissions() {
    const dbPermissions = (await this.db.ref('permissions').once('value')).val()
    const permissions = Object.values(dbPermissions).map((p) => ({
      ...p,
      roles: Object.keys(p.roles || {}),
    }))
    return permissions
  }

  async updateRole(rid, pid, state) {
    await this.db.ref(`/roles/${rid}/permissions/${pid}`).set(!!state || null)
  }

  async addRole(title) {
    const rid =
      title.charAt(0).toLowerCase() + title.substr(1).replaceAll(' ', '')
    const snap = await this.db.ref(`/roles/${rid}`).once('value')
    if (snap.exists()) {
      throw new Error(`${rid} already exists`)
    } else {
      await this.db
        .ref(`/roles/${rid}`)
        .set({ rid, title, permissions: {}, users: {} })
      return true
    }
  }

  async deleteRole(rid) {
    const snap = (await this.db.ref(`/roles/${rid}`).once('value'))

    if(!snap.exists()) {
      throw new Error(`${rid} does not exist`)
    }

    const role = snap.val()
    await Promise.all(Object.keys(role.users || {}).map(uid => (
      this.db.ref(`/users/${uid}/roles/${rid}`).remove()
    )))
    await this.db.ref(`/roles/${rid}`).remove()
    return true
  }
}

export default Firebase
