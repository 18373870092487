export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const ADMIN = '/admin'
export const ADMIN_DETAILS = '/admin/details/:id'
export const ADMIN_ROLES = '/admin/roles'
export const SEARCH = '/search'
export const SEARCH_BY_NAME = '/search-by-name'
export const USERACCOUNT = '/accounts/:accountId/serviceUsers/:serviceUserId/devices/:dsn'
export const SERVICEUSERS = '/accounts/:accountId/serviceUsers'
export const DEVICEV1 = '/accounts/:dsn'
export const ACCOUNTDEVICE = '/accounts/:accountId/devices/:dsn'
