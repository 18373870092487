import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import '../Layout/Buttons.css'
import PropTypes from 'prop-types'


const TextModal = ({ open, handleClose, text, title, toExecute }) => {
  return <Dialog open={open} onClose={handleClose} >
    <DialogTitle>{title}</DialogTitle>
    <IconButton
      aria-label="close"
      className="modalCloseBtn"
      disableFocusRipple
      disableRipple
      disableTouchRipple
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent>
      <DialogContentText>{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant='outlined' className="filledBtn" onClick={() => toExecute()}>Confirm</Button>
    </DialogActions>
  </Dialog>
}

TextModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  toExecute: PropTypes.func,
}

TextModal.defaultProps = {
  handleClose: () => {},
  text: '',
  title: '',
  toExecute: () => {}
}

export default TextModal
