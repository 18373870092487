import React, { useContext, useState } from 'react'
import { compose } from 'recompose'
import validator from 'email-validator'
import { SnackbarContext } from '../common/Snackbar'
import {
    getDeviceByDsn,
    getUserAndAssociatedDevicesByEmail,
    getUserAndAssociatedDevicesByAccountId,
} from '../../api/index'
import { withAuthorization, withEmailVerification } from '../Session'
import Search from './Search'
import { isAlphanumeric } from '../common/helpers/helpers'
import permissions from '../../constants/permissions'


const SearchContainer = () => {
    const [userDevices, setUserDevices] = useState()
    const [accountInfoWithDevicesAndServiceUsers, setAccountInfoWithDevicesAndServiceUsers] = useState()
    const [inputValues, setInputValues] = useState({ DSNInput: '', emailInput: '' })
    const [isLoading, setIsLoading] = useState(false)
    const [completedSearchType, setCompletedSearchType] = useState(null)
    const snackbar = useContext(SnackbarContext)

    const handleDevicesChange = event => {
        setInputValues({ DSNInput: event.target.value.trim(), emailInput: '' })
    }

    const handleEmailChange = event => {
        setInputValues({ emailInput: event.target.value.trim(), DSNInput: '' })
    }

    const searchByDsn = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        setAccountInfoWithDevicesAndServiceUsers(null) // clear out accounts
        const deviceData = await getDeviceByDsn(inputValues.DSNInput.toUpperCase())
        setUserDevices(deviceData)
        setCompletedSearchType('device')
        setIsLoading(false)
    }

    const searchByEmail = async (event) => {
        event.preventDefault()
        try {
          setIsLoading(true)
          setUserDevices(null) // clear out devices

          if (inputValues.emailInput && validator.validate(inputValues.emailInput.toLowerCase())) {
              const userData = await getUserAndAssociatedDevicesByEmail(inputValues.emailInput.toLowerCase())
              setAccountInfoWithDevicesAndServiceUsers(userData)
          } else if (isAlphanumeric(inputValues.emailInput)) {
              const userData = await getUserAndAssociatedDevicesByAccountId(inputValues.emailInput)
              setAccountInfoWithDevicesAndServiceUsers(userData)
          } else {
              snackbar.openSnackbar('Not a valid email or account ID. Please check spelling and try again.')
          }

          setCompletedSearchType('user')
          setIsLoading(false)
        } catch (e) {
          console.error(e)
        }
    }

    return (
        <Search
            accountInfoWithDevicesAndServiceUsers={accountInfoWithDevicesAndServiceUsers}
            completedSearchType={completedSearchType}
            handleDevicesChange={handleDevicesChange}
            handleEmailChange={handleEmailChange}
            inputValues={inputValues}
            isLoading={isLoading}
            searchByDsn={searchByDsn}
            searchByEmail={searchByEmail}
            userDevices={userDevices}
        />
    )
}

export { SearchContainer as SearchContainerTest } // used to be able to test component directly
export default compose(
    withEmailVerification,
    withAuthorization([permissions.viewSearch]),
)(SearchContainer)
