import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'

import '../Layout/Layout.css'
import '../Layout/Buttons.css'


class ErrorBoundary extends React.Component {

    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        history: PropTypes.shape({
            goBack: PropTypes.func
        }).isRequired
    }

    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo
        })
    }

    backToPreviousPath = () => {
        const { history } = this.props
        history.goBack()
        this.setState({ error: null, errorInfo: null })
    }

    render() {
        const { errorInfo, error } = this.state
        const { children } = this.props


        if (errorInfo) {
            return (
                <div className="errorBoundaryContainer">
                    <Logo className="owlIcon" />
                    <h1>Owl be darned! Something unexpected happened...</h1>
                    <Button onClick={this.backToPreviousPath} onKeyPress={this.backToPreviousPath} variant="outlined" className="emptyBtn">Go Back!</Button>
                    <br />
                    {process.env.NODE_ENV === 'development' ?
                        <details>
                            <p><strong>{error && error.toString()}</strong></p>
                            {errorInfo.componentStack}
                        </details>
                        : null}
                </div>
            );
        }
        return children;
    }
}



export default withRouter(ErrorBoundary)
