import { Tabs, Tab, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { monthsToYears } from '../../common/helpers/helpers'


const OtherAssociatedDevices = ({ selectedProfileTabId, handleChange, associatedProfiles, areAssociatedProfilesLoading }) => {
    if (areAssociatedProfilesLoading) {
        return null
    }
    return (
        <Paper>
            <Paper position="static">
                <Tabs
                    value={selectedProfileTabId}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {associatedProfiles.length !== 0 ? associatedProfiles.map(associatedProfilesInfo => {
                        return <Tab value={associatedProfilesInfo.serviceUserId} key={associatedProfilesInfo.serviceUserId} label={associatedProfilesInfo.firstName} />
                    }) : <Tab label="No Associated Profiles" />}
                </Tabs>
            </Paper>
            {
                associatedProfiles.map((accountProfilesInfo) => {
                    return (
                        selectedProfileTabId === accountProfilesInfo.serviceUserId && (
                            <div className="childInfo" key={accountProfilesInfo.serviceUserId}>
                                <dl className="defList">
                                    <dt>Child Name</dt>
                                    <dd>{accountProfilesInfo.firstName}</dd>

                                    <dt>Service User ID</dt>
                                    <dd>{accountProfilesInfo.serviceUserId}</dd>

                                    <dt>Age</dt>
                                    <dd>{monthsToYears(accountProfilesInfo.birthdate)}</dd>
                                </dl>
                            </div>
                        )
                    )
                })
            }
        </Paper >
    )
}

OtherAssociatedDevices.propTypes = {
    associatedProfiles: PropTypes.arrayOf(
        PropTypes.shape({
            serviceUserId: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            age: PropTypes.number,
            accountId: PropTypes.string,
            deviceId: PropTypes.string,
            name: PropTypes.string,
            birthDate: PropTypes.string
        })
    ),
    selectedProfileTabId: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    areAssociatedProfilesLoading: PropTypes.bool.isRequired
}

OtherAssociatedDevices.defaultProps = {
    associatedProfiles: [],
}

export default OtherAssociatedDevices
