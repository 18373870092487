import React, { useContext, useEffect, useState } from 'react'

import AuthUserContext from '../Session/context'

import Landing from './Landing'


const LandingContainer = () => {
  const authUser = useContext(AuthUserContext)
  const [authenticated, setAuthenticated] = useState(false)
  const [landingCard, setLandingCard] = useState(true)
  const [createAccountCard, setCreateAccountCard] = useState(false)
  const [passwordForgetCard, setPasswordForgetCard] = useState(false)

  useEffect(() => {
    if (authUser) {
      setAuthenticated(true)
    }
  }, [authUser])

  const handleLandingCardShow = () => {
    setLandingCard(true)
  }
  const handleCreateAccountCardShow = () => {
    setCreateAccountCard(true)
    setLandingCard(false)
  }
  const handleSignInCardShow = () => {
    setCreateAccountCard(false)
    setLandingCard(false)
    setPasswordForgetCard(false)
  }
  const handlePasswordForgetCardShow = () => {
    setPasswordForgetCard(true)
  }
  return (
    <Landing
      landingCard={landingCard}
      createAccountCard={createAccountCard}
      passwordForgetCard={passwordForgetCard}
      handleLandingCardShow={handleLandingCardShow}
      handleCreateAccountCardShow={handleCreateAccountCardShow}
      handleSignInCardShow={handleSignInCardShow}
      handlePasswordForgetCardShow={handlePasswordForgetCardShow}
      authenticated={authenticated}
    />
  )
}
export {LandingContainer as LandingContainerTest}
export default LandingContainer
