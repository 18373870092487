import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import '../../../common/Layout/Buttons.css'


const UnregisterModal = ({
    currentDevice,
    hideUnregisterModal,
    isUnregisterModalShown,
    showUnregisterModal,
    unregisterDeviceFromUser,
}) => {
    return (
        <>
            <Button
                className="emptyBtn emptySmaller"
                variant="outlined"
                onClick={showUnregisterModal}
            >
                Unregister
            </Button>
            <Dialog
                onClose={hideUnregisterModal}
                open={isUnregisterModalShown}
            >
                <IconButton
                    aria-label="close"
                    className="modalCloseBtn"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onClick={hideUnregisterModal}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>Unregister device from user?</DialogTitle>
                <DialogContent>
                    <p>This action will unregister this device from the account.</p>
                    <DialogActions>
                        <Button className="emptyBtn emptySmaller" variant="outlined" onClick={() => unregisterDeviceFromUser(currentDevice.dsn, currentDevice.accountId)}>Unregister</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

UnregisterModal.propTypes = {
    currentDevice: PropTypes.shape({
        accountId: PropTypes.string,
        dsn: PropTypes.string,
        serviceUserId: PropTypes.string,
    }),
    hideUnregisterModal: PropTypes.func.isRequired,
    isUnregisterModalShown: PropTypes.bool.isRequired,
    showUnregisterModal: PropTypes.func.isRequired,
    unregisterDeviceFromUser: PropTypes.func.isRequired,
}

UnregisterModal.defaultProps = {
    currentDevice: {}
}

export default UnregisterModal
