import React, { useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import DateTimePicker from 'react-datetime-picker'
import { generateSleepData } from '../../../../api/lib/apiV1'
import TextModal from '../../../common/TextModal/TextModal'
import '../../../common/Layout/Buttons.css'
import { SnackbarContext } from '../../../common/Snackbar'


const GenerateSleepData = ({ currentDevice }) => {
  const { accountId, deviceId } = currentDevice
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const defaultStartTime = new Date()
  const defaultEndTime = new Date()
  const DURATION_LIMIT_MS = (24 * 60 * 60 * 1000) // 24 hour limit

  // Default start time is two hours behind end time
  defaultStartTime.setHours(defaultStartTime.getHours() - 2)

  const [startTime, onStartChange] = useState(defaultStartTime)
  const [endTime, onEndChange] = useState(defaultEndTime)
  const text = `Warning! This process could take up to 5 minutes to complete.
                Please do not click this button again within that timeframe!`
  const snackbar = useContext(SnackbarContext)

  const showConvertModal = () => {
    setIsModalOpen(true)
  }

  const hideConvertModal = () => {
    setIsModalOpen(false)
  }

  const handleGenerateData = async () => {
    const duration = endTime - startTime

    if (duration < 0) {
      snackbar.openSnackbar(
        'End date must be greater than start date.'
      )
      return
    } 

    if (duration > DURATION_LIMIT_MS) {
      snackbar.openSnackbar(
        'Duration must be less than 24 hours.'
      )
      return
    }

    const generateSleepDataResult = await generateSleepData(accountId, deviceId, startTime, duration)

    if (generateSleepDataResult) {
      snackbar.openSnackbar(
        `Generate sleep data request sent successfully. Please wait 5 minutes for sleep data to appear in app.`
      )
    } else {
      snackbar.openSnackbar(
        'Error sending generate sleep data request. Please try again.'
      )
    }

    setIsButtonDisabled(true)
    setIsModalOpen(false)
  }

  return (
    <div>
      <Button
        className="emptyBtn emptySmaller"
        disabled={isButtonDisabled}
        variant="outlined"
        onClick={showConvertModal}>
        Generate Data
      </Button>
      <nobr>Start: <DateTimePicker onChange={onStartChange} value={startTime} /></nobr>
      <nobr>End: <DateTimePicker onChange={onEndChange} value={endTime} /></nobr>
      <TextModal
        open={isModalOpen}
        handleClose={hideConvertModal}
        title="Generate sleep data"
        text={text}
        toExecute={handleGenerateData}
      />
    </div>
  )
}

GenerateSleepData.propTypes = {
  authUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.string
    ),
    roles: PropTypes.shape(PropTypes.string),
  }),
  currentDevice: PropTypes.shape({
    accountId: PropTypes.string,
    deviceId: PropTypes.string,
    type: PropTypes.string,
  }),
}

GenerateSleepData.defaultProps = {
  authUser: {},
  currentDevice: {},
}

export default GenerateSleepData
