import {
  Edit,
  Check,
  Clear,
  Search,
  AddBox,
  Remove,
  SaveAlt,
  LastPage,
  FirstPage,
  ViewColumn,
  FilterList,
  ArrowUpward,
  ChevronLeft,
  ChevronRight,
  DeleteOutline,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import React, { useState, forwardRef } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Checkbox, Tooltip, Box, Typography } from '@material-ui/core'

import { withFirebase } from '../../Firebase'
import * as ROUTES from '../../../constants/routes'
import { useEmployee } from '../contexts/EmployeeContext'
import WithLoadingSpinner from '../../common/LoadingSpinner/withLoadingSpinner'

const tableIcons = {
  /* eslint-disable react/jsx-props-no-spreading */
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  /* eslint-enable react/jsx-props-no-spreading */
}

const EmployeeUserList = ({ authUser }) => {
  const { users, loading } = useEmployee()
  const [exportAll, setExportAll] = useState(false)
  const handleExportAll = () => setExportAll(!exportAll)

  if (loading)
    return <CircularProgress className="loadSpin" data-testid="loadingState" />

  return (
    <Box px={3} data-testid="loadedState">
      <Box component={Typography} mb={2} variant="h2">
        Employee Management
      </Box>
      <MaterialTable
        options={{
          exportButton: authUser.permissions.includes('exportOMPManagement'),
          exportAllData: exportAll,
          exportFileName: 'Employees',
          pageSize: 5,
          actionsColumnIndex: -1,
          headerStyle: {
            color: '#73c7ad',
          },
          rowStyle: {
            color: '#6d6e70',
          },
        }}
        icons={tableIcons}
        columns={[
          {
            title: 'Employee Name',
            field: 'firstName',
            render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
          },
          {
            title: 'Email',
            field: 'email',
          },
        ]}
        data={users}
        title={null}
        components={
          authUser.permissions.includes('exportOMPManagement')
            ? {
                actions: () => (
                  // eslint-disable-line react/no-unstable-nested-components
                  <Tooltip title="Export All" placement="bottom">
                    <Checkbox
                      checked={exportAll}
                      onChange={handleExportAll}
                      color="default"
                      data-testid="exportAllCheckbox"
                    />
                  </Tooltip>
                ),
              }
            : null
        }
        onRowClick={(event, rowData) =>
          window.location.replace(
            ROUTES.ADMIN_DETAILS.replace(':id', rowData.uid),
          )
        }
      />
    </Box>
  )
}

EmployeeUserList.propTypes = {
  authUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export default withFirebase(WithLoadingSpinner(EmployeeUserList))
