import { Tabs, Tab, Paper } from '@material-ui/core'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import React from 'react'
import * as ROUTES from '../../../constants/routes'
import '../../../index.css'


const AssociatedDevices = ({
    areUserDevicesLoading,
    handleChange,
    selectedDeviceTabId,
    userDevices,
}) => {
    if (areUserDevicesLoading) {
        return null
    }
    return (
        <Paper>
            <Paper position="static">
                <Tabs
                    indicatorColor="primary"
                    onChange={handleChange}
                    scrollButtons="on"
                    textColor="primary"
                    value={selectedDeviceTabId}
                    variant="scrollable"
                >
                    {userDevices.length !== 0 ? userDevices.map((userDevicesInfo) => {
                        return <Tab value={userDevicesInfo.dsn} key={userDevicesInfo.dsn} label={userDevicesInfo.type} />
                    }) : <Tab label="No Other Devices" />}
                </Tabs>
            </Paper>
            {
                userDevices.map((accountDevicesInfo) => {
                    return (
                        selectedDeviceTabId === accountDevicesInfo.dsn && (
                            <div key={accountDevicesInfo.dsn}>
                                <dl className="defList">
                                    <dt>DSN</dt>
                                    <dd><Link to={ROUTES.ACCOUNTDEVICE.replace(':accountId', accountDevicesInfo.accountId).replace(':dsn', accountDevicesInfo.dsn)} className="links">{accountDevicesInfo.dsn}</Link></dd>

                                    <dt>Associated User</dt>
                                    <dd>{accountDevicesInfo.userProfile.firstName}</dd>

                                    <dt>Activation Date</dt>
                                    <dd>{accountDevicesInfo.activatedDate ? format(new Date(accountDevicesInfo.activatedDate), 'LLLL dd yyyy') : 'Not Available'}</dd>
                                </dl>
                            </div>
                        )
                    )
                })
            }
        </Paper >
    )
}

AssociatedDevices.propTypes = {
    areUserDevicesLoading: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    selectedDeviceTabId: PropTypes.string.isRequired,
    userDevices: PropTypes.arrayOf(
        PropTypes.shape({
            activatedDate: PropTypes.string,
            aylaEnvironment: PropTypes.string,
            deviceKey: PropTypes.string,
            dsn: PropTypes.string,
            firmwareVersion: PropTypes.string,
            lastOnlineTime: PropTypes.string,
            macAddress: PropTypes.string,
            registrationDate: PropTypes.string,
            serviceUser: PropTypes.string,
            type: PropTypes.string,
        })
    ),


}

AssociatedDevices.defaultProps = {
    areUserDevicesLoading: undefined,
    userDevices: [],
}

export default AssociatedDevices
