import React, { useEffect, createRef, useState } from 'react'
import {
  Box,
  Grid,
  Paper,
  Table,
  Select,
  Dialog,
  Button,
  Hidden,
  TableRow,
  Checkbox,
  Accordion,
  TableHead,
  TableCell,
  TableBody,
  TextField,
  InputLabel,
  makeStyles,
  Typography,
  FormControl,
  DialogTitle,
  DialogActions,
  TableContainer,
  AccordionSummary,
  AccordionDetails,
  DialogContentText,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useRoles } from '../contexts/RolesContext'

const useStyles = makeStyles((theme) => ({
  actions: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  action: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  table: { borderCollapse: 'separate' },
  tableViewport: { position: 'relative', flexGrow: 1 },
  tableContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 2,
    overflow: 'auto',
    position: 'absolute',
  },
  stickyCol: {
    left: 0,
    zIndex: 1,
    width: 200,
    minWidth: 200,
    position: 'sticky',
    backgroundColor: '#fafafa',
    borderRight: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]: { minWidth: 100, width: 100 },
  },
  stickyRow: {
    top: 0,
    zIndex: 1,
    minWidth: 150,
    position: 'sticky',
    backgroundColor: '#fafafa',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]: { minWidth: 100 },
  },
  stickyCorner: {
    top: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
    fontStyle: 'italic',
    fontSize: '0.75rem',
    backgroundColor: '#fafafa',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    color: theme.palette.text.secondary,
  },
}))

const LocalAccordion = withStyles((theme) => ({
  root: {
    margin: 0,
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:before': { display: 'none' },
    '&$expanded': {
      margin: 0,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  expanded: {},
}))(Accordion)

const LocalAccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 48,
    '&$expanded': { minHeight: 48 },
  },
  content: {
    margin: 0,
    '&$expanded': { margin: 0 },
  },
  expanded: {},
})(AccordionSummary)

const LocalAccordionDetails = withStyles({
  root: { paddingLeft: 0, paddingRight: 0 },
})(AccordionDetails)

export default function RolesManagement() {
  const theme = useTheme()
  const table = createRef()
  const classes = useStyles()
  const newRoleField = createRef()
  const deleteRoleSelect = createRef()
  const [shownRoles, setShownRoles] = useState([])
  const [roleFilter, setRoleFilter] = useState('')
  const [deletingRole, setDeletingRole] = useState(false)
  const [addingNewRole, setAddingNewRole] = useState(false)
  const [permissionFilter, setPermissionFilter] = useState('')
  const [shownPermissions, setShownPermissions] = useState([])
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const expandAccordion = useMediaQuery(theme.breakpoints.up('sm'))
  const { roles, permissions, updateRole, loading, addRole, deleteRole } =
    useRoles()

  async function handleUpdateRole(rid, pid, e) {
    e.target.disabled = true
    await updateRole(rid, pid, e.target.checked)
    e.target.disabled = false
  }

  async function handleAddRole() {
    setAddingNewRole(false)
    await addRole(newRoleField.current.value)
  }

  async function handleDeleteRole() {
    await deleteRole(deleteRoleSelect.current.value)
    setDeletingRole(false)
  }

  useEffect(() => {
    if (roleFilter) {
      setShownRoles(roles.filter((r) => r.rid === roleFilter))
    } else {
      setShownRoles(roles)
    }

    if (permissionFilter) {
      setShownPermissions(permissions.filter((p) => p.pid === permissionFilter))
    } else {
      setShownPermissions(permissions)
    }
  }, [permissionFilter, permissions, roles, roleFilter])

  useEffect(() => {
    const { current } = table

    function horizontalScroll(e = window.event) {
      if (!e.shiftKey) return
      e.preventDefault()
      const delta = e.deltaX || e.deltaY
      if (current) current.scrollLeft += delta
    }

    if (current) current.addEventListener('wheel', horizontalScroll)

    return () => {
      if (current) current.removeEventListener('wheel', horizontalScroll)
    }
  }, [table])

  if (loading) {
    return <CircularProgress className="loadSpin" data-testid="loadingState" />
  }

  return (
    <>
      <LocalAccordion defaultExpanded={expandAccordion} square>
        <LocalAccordionSummary
          expandIcon={<ExpandMore />}
          id="roles-management-header"
          aria-controls="roles-management-tools"
        >
          <Typography variant="h2">Roles Management</Typography>
        </LocalAccordionSummary>
        <LocalAccordionDetails>
          <Grid
            container
            spacing={4}
            wrap="nowrap"
            alignItems="center"
            justifyContent="flex-end"
            data-testid="loadedState"
            className={classes.actions}
          >
            <Grid item xs={12} sm={6} md={3} lg={2} className={classes.action}>
              <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id="roles-filter">Filter By Role</InputLabel>
                <Select
                  native
                  value={roleFilter}
                  label="Filter By Role"
                  labelId="roles-filter"
                  onChange={(e) => setRoleFilter(e.target.value)}
                >
                  <option aria-label="None" value="" />
                  {roles?.map((r) => (
                    <option key={`filter_${r.rid}`} value={r.rid}>
                      {r.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} className={classes.action}>
              <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id="permissions-filter">
                  Filter By Permission
                </InputLabel>
                <Select
                  native
                  value={permissionFilter}
                  label="Filter By Permission"
                  labelId="permisssions-filter"
                  onChange={(e) => setPermissionFilter(e.target.value)}
                >
                  <option aria-label="None" value="" />
                  {permissions?.map((p) => (
                    <option key={`filter_${p.pid}`} value={p.pid}>
                      {p.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} lg={2} className={classes.action}>
              <Button
                fullWidth
                size="small"
                color="primary"
                variant="contained"
                className={classes.button}
                aria-label="open add role dialog"
                onClick={() => setAddingNewRole(true)}
              >
                Add Role
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className={classes.action}>
              <Button
                fullWidth
                size="small"
                color="secondary"
                variant="contained"
                className={classes.button}
                aria-label="open delete role dialog"
                onClick={() => setDeletingRole(true)}
              >
                Delete Role
              </Button>
            </Grid>
          </Grid>
        </LocalAccordionDetails>
      </LocalAccordion>

      <Box mx={isMobile ? 1 : 2} mt={2} className={classes.tableViewport}>
        <TableContainer
          ref={table}
          component={Paper}
          className={classes.tableContainer}
        >
          <Table
            className={classes.table}
            aria-label="roles and permissions table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.stickyCorner} align="center">
                  <Hidden smDown>
                    <Box px={2}>
                      Tip: hold SHIFT while scrolling for horizontal
                    </Box>
                  </Hidden>
                </TableCell>
                {shownRoles?.map((r) => (
                  <TableCell
                    align="center"
                    className={classes.stickyRow}
                    key={r.rid}
                  >
                    {r.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {shownPermissions?.map((p) => (
                <TableRow key={p.pid}>
                  <TableCell className={classes.stickyCol}>{p.title}</TableCell>
                  {shownRoles.map((r) => {
                    return (
                      <TableCell align="center" key={`${p.pid}_${r.rid}`}>
                        <Checkbox
                          checked={r.permissions.includes(p.pid)}
                          onChange={(e) => handleUpdateRole(r.rid, p.pid, e)}
                          inputProps={{
                            'aria-label': `checkbox for permission ${p.pid} for role ${r.rid}`,
                          }}
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        fullWidth
        maxWidth="xs"
        fullScreen={isMobile}
        open={!!addingNewRole}
        aria-labelledby="add-new-role-title"
      >
        <Box component={DialogTitle} p={3} id="add-new-role-title">
          Add New Role
        </Box>
        <DialogContentText component="div">
          <Box px={3}>
            <TextField
              fullWidth
              autoFocus
              size="small"
              label="New Role Title"
              inputProps={{ ref: newRoleField }}
            />
          </Box>
        </DialogContentText>
        <Box component={DialogActions} m={3}>
          <Button
            color="secondary"
            variant="contained"
            aria-label="add new role"
            onClick={() => handleAddRole()}
          >
            Add
          </Button>
          <Button
            color="primary"
            aria-label="cancel adding role"
            onClick={() => setAddingNewRole(false)}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={!!deletingRole}
        fullScreen={isMobile}
        aria-labelledby="delete-role-title"
      >
        <Box component={DialogTitle} p={3} id="delete-role-title">
          Delete A Role
        </Box>
        <DialogContentText component="div">
          <Box px={3}>
            <FormControl fullWidth>
              <InputLabel id="role-delete">Select a role to delete</InputLabel>
              <Select
                native
                autoFocus
                labelId="role-delete"
                label="Select a role to delete"
                inputProps={{ ref: deleteRoleSelect }}
              >
                <option aria-label="None" value="" />
                {roles?.map((r) => (
                  <option key={`filter_${r.rid}`} value={r.rid}>
                    {r.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContentText>
        <Box component={DialogActions} m={3}>
          <Button
            color="secondary"
            variant="contained"
            aria-label="delete role"
            onClick={() => handleDeleteRole()}
          >
            Delete
          </Button>
          <Button
            color="primary"
            aria-label="cancel delete role"
            onClick={() => setDeletingRole(false)}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  )
}
