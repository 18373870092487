import { Button, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import "../common/Layout/Buttons.css"


const PasswordForget = ({ onSubmit, register, clearFormError, error, isSubmitting }) => {
  return (
    <form className="formWithValidation" onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="E-mail address"
            variant="outlined"
            {...register('email')}
            onChange={clearFormError}
            fullWidth
          />
          {error && (
            <span className="errorMessages">
              An error occurred. We were unable to reset that password.
            </span>
          )}
        </Grid>
      </Grid>
      <br />
      <Button
        className="filledBtn"
        variant="contained"
        fullWidth
        disabled={isSubmitting}
        type="submit"
      >
        Send Reset Password Email
      </Button>
    </form>
  )
}

PasswordForget.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  clearFormError: PropTypes.func,
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  }),
  isSubmitting: PropTypes.bool.isRequired,
}

PasswordForget.defaultProps = {
  clearFormError: () => { },
  error: { code: "None", message: "No Error" }
}

export default PasswordForget
