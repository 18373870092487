import { Divider, ListItemIcon } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import React from 'react'


const HelpSearchByName = () => {
    return (
        <div>
            <h2>Search By Name Page:</h2>
            <p>This page allows you to search for users using a first or last name.</p>
            <Divider />
            <h3>Search By First Name...</h3>
            <p>Case sensitive. Put the first name of the person you are looking for in this field.</p>
            <Divider />
            <h3>Search By Last Name...</h3>
            <p>Case sensitive. Put the last name of the person you are looking for in this field.</p>
            <Divider />
            <ListItemIcon><SearchIcon /></ListItemIcon>
            <p>Click here after inputting the name of the person you want to search for. Alternatively, you can press &ldquo;Enter&rdquo; on your keyboard.</p>
            <Divider />
            <h3>Account ID</h3>
            <p>ID specific to this account.</p>
            <Divider />
            <h3>User Name</h3>
            <p>User&apos;s name. Sometimes will only contain first name if user did not provide last.</p>
            <Divider />
            <h3>User Name</h3>
            <p>User&apos;s phone number.</p>
            <Divider />
            <h3>Region</h3>
            <p>Region in which the user registered the account.</p>
            <Divider />
            <h3>DSN</h3>
            <p>This is the unique identifier for a device. AC = Sock, OC = Camera, OB = Band</p>
            <Divider />
            <h3>Associated Devices</h3>
            <p>The devices that are associated to this account.</p>
            <Divider />
            <h3>Devices Active</h3>
            <p>The devices that are active on this account.</p>
            <Divider />
            <h3>Email</h3>
            <p>Email associated to the account.</p>
            <Divider />
            <h3>Registered On</h3>
            <p>The date that the displayed device is registered to the current account.</p>
            <Divider />
            <h3>View More Details</h3>
            <p>Click this button to see more information on the displayed device. If the button is disabled, there is no more info available for this account.</p>
        </div>
    )
}

export default HelpSearchByName
