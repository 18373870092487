import { Button, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'


const SignIn = ({ onSubmit, loginError, register, isSubmitting }) => {
  return (
    <form className="formWithValidation" onSubmit={onSubmit}>
      {loginError && (
        <span className="errorMessages">
          Invalid login or password. Please try again.
        </span>
      )}
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register('email')}
            label="E-mail address"
            variant="outlined"
            type="email"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('password')}
            label="Your password"
            variant="outlined"
            type="password"
            fullWidth
          />
        </Grid>
      </Grid>
      <br />
      <Button
        className="filledBtn"
        variant="contained"
        fullWidth
        disabled={isSubmitting}
        type="submit"
      >
        Sign In
      </Button>
    </form>
  )
}

SignIn.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loginError: PropTypes.shape({
    error: PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string
    })
  }),
  register: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

SignIn.defaultProps = {
  loginError: { code: "None", message: "No Error" }
}

export default SignIn
