import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => ({
  button: { width: 230 },
}))

const SocialLoginToggle = ({
  isEnabled,
  signInMethod,
  onlyOneLeft,
  onUnlink,
  onLink,
}) => {
  const classes = useStyles()
  if (isEnabled) {
    return (
      <Button
        type="button"
        disabled={onlyOneLeft}
        className={classes.button}
        onClick={() => onUnlink(signInMethod.id)}
      >
        Deactivate {signInMethod.id}
      </Button>
    )
  }
  return (
    <Button
      type="button"
      color="primary"
      variant="contained"
      className={classes.button}
      onClick={() => onLink(signInMethod.provider)}
    >
      Link {signInMethod.id}
    </Button>
  )
}

SocialLoginToggle.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  signInMethod: PropTypes.shape({
    id: PropTypes.string,
    provider: PropTypes.string,
  }).isRequired,
  onlyOneLeft: PropTypes.bool.isRequired,
  onUnlink: PropTypes.func.isRequired,
  onLink: PropTypes.func.isRequired,
}

export default SocialLoginToggle
