import { Button, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'


const SignUp = ({ onSubmit, errors, register, clearFormError, formError, isSubmitting, password }) => {
  return (
    <form className="formWithValidation" onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            className="inputField"
            fullWidth
            label="First Name"
            variant="outlined"
            {...register('firstName', { required: true, minLength: 2 })}
          />
          {errors.firstName &&
            errors.firstName.type === 'required' && (
              <span className="errorMessages">First Name is required.</span>
            )}
          {errors.firstName &&
            errors.firstName.type === 'minLength' && (
              <span className="errorMessages">
                Your first name needs a minimum of 2 characters.
              </span>
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            {...register('lastName', { required: true, minLength: 2 })}
          />
          {errors.lastName &&
            errors.lastName.type === 'required' && (
              <span className="errorMessages">Last Name is required.</span>
            )}
          {errors.lastName &&
            errors.lastName.type === 'minLength' && (
              <span className="errorMessages">
                Your last name needs a minimum of 2 characters.
              </span>
            )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="E-mail address"
            variant="outlined"
            type="email"
            {...register('email', {
              required: true,
              // eslint-disable-next-line no-useless-escape
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            onChange={clearFormError}
          />
          {errors.email && errors.email.type === 'required' && (
            <span className="errorMessages">E-mail address is requried.</span>
          )}
          {errors.email && errors.email.type === 'pattern' && (
            <span className="errorMessages">Enter a valid email address.</span>
          )}
          {formError && (
            <span className="errorMessages">
              The email address cannot be used to create an account.
            </span>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            fullWidth
            label="Password"
            variant="outlined"
            {...register('password', {
              required: true,
              minLength: 8,
            })}
          />
          {errors.password &&
            errors.password.type === 'required' && (
              <span className="errorMessages">Password is requried.</span>
            )}
          {errors.password &&
            errors.password.type === 'minLength' && (
              <span className="errorMessages">
                Password must have at least 8 characters.
              </span>
            )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            label="Confirm password"
            variant="outlined"
            fullWidth
            {...register('passwordConfirm', {
              required: true,
              validate: value =>
                value === password.current ||
                'The passwords do not match',
            })}
          />
          {errors.passwordConfirm &&
            errors.passwordConfirm.type === 'required' && (
              <span className="errorMessages">
                Confirm password is requried.
              </span>
            )}
          {errors.passwordConfirm &&
            errors.passwordConfirm.type === 'validate' && (
              <span className="errorMessages">
                {errors.passwordConfirm.message}.
              </span>
            )}
        </Grid>
      </Grid>
      <br />
      <Button
        className="filledBtn"
        variant="contained"
        fullWidth
        disabled={isSubmitting}
        type="submit"
      >
        Create Account
      </Button>
    </form>
  )
}

SignUp.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.shape({ type: PropTypes.string, message: PropTypes.string }),
    lastName: PropTypes.shape({ type: PropTypes.string, message: PropTypes.string }),
    email: PropTypes.shape({ type: PropTypes.string, message: PropTypes.string }),
    password: PropTypes.shape({ type: PropTypes.string, message: PropTypes.string }),
    passwordConfirm: PropTypes.shape({ type: PropTypes.string, message: PropTypes.string })
  }),
  register: PropTypes.func.isRequired,
  clearFormError: PropTypes.func.isRequired,
  formError: PropTypes.shape({ error: PropTypes.string }),
  isSubmitting: PropTypes.bool.isRequired,
  password: PropTypes.shape({ current: PropTypes.string })
}

SignUp.defaultProps = {
  formError: {},
  errors: {},
  password: { current: '' }
}

export default SignUp
