import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import './Spinner.css'


const WithLoadingSpinner = Component => {

  return function WithLoadingComponent({ loading, ...props }) { // TODO: change to isLoading everywhere
    if (loading) {
      WithLoadingComponent.propTypes = {
        loading: PropTypes.bool.isRequired,
      }
      return (
        <CircularProgress className="loadSpin">
          <span className="sr-only">Loading &hellip;</span>
        </CircularProgress>
      )
    }
    // eslint-disable-next-line
    return <Component {...props} />
  }

}

export default WithLoadingSpinner
