import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { withFirebase } from '../Firebase'

import AuthUserContext from './context'

import "../DeviceOverview/Device.css"
import "../common/Layout/Buttons.css"


const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password')

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props)

      this.state = { isSent: false }
    }

    onSendEmailVerification = () => {
      const { firebase } = this.props
      firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }))
    }

    render() {
      const { isSent } = this.state
      WithEmailVerification.propTypes = {
        firebase: PropTypes.shape({
          doSendEmailVerification: PropTypes.func,
        }).isRequired,
      }
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <div>
                {isSent ? (
                  <div>
                    <p>
                      E-Mail confirmation sent: Check your E-Mails (Spam folder
                      included) for a confirmation E-Mail.
                    </p>
                    <p>
                      <b>Refresh this page once you confirmed your E-Mail.</b>
                    </p>
                  </div>
                ) : (
                    <div>
                      <p>
                        Verify your E-Mail: Check your E-Mails (Spam folder
                        included) for a confirmation E-Mail or send another
                        confirmation E-Mail.
                    </p>
                    </div>
                  )}

                <Button
                  onClick={this.onSendEmailVerification}
                  disabled={isSent}
                  className="filledBtn"
                  variant="contained"
                >
                  {isSent
                    ? 'Confirmation E-Mail has sent'
                    : 'Send confirmation E-Mail'}
                </Button>
              </div>
            ) : (
                <Component history={this.history} location={this.location} match={this.match} staticContext={this.staticContext} firebase={this.firebase} />
              )
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return withFirebase(WithEmailVerification)
}

export default withEmailVerification
