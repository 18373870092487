import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'

import UserInfo from './UserInfo'
import DeviceInfo from './DeviceInfo'
import '../../common/Layout/Buttons.css'
import WithLoadingSpinner from '../../common/LoadingSpinner/withLoadingSpinner'

const SearchResults = ({
  userDevices,
  completedSearchType,
  accountInfoWithDevicesAndServiceUsers,
}) => {
  let devices = null
  let users = null

  if (userDevices) {
    devices = userDevices
  }

  if (accountInfoWithDevicesAndServiceUsers) {
    users = Array.isArray(accountInfoWithDevicesAndServiceUsers)
      ? accountInfoWithDevicesAndServiceUsers
      : [accountInfoWithDevicesAndServiceUsers]
  }

  if (!users && !devices && completedSearchType) {
    return <div data-testid="noResults">No {completedSearchType}s found</div>
  }

  return (
    <>
      {devices?.map((device) => (
        <Paper key={device.dsn}>
          <DeviceInfo device={device} />
        </Paper>
      ))}
      {users?.map((user) => (
        <Paper key={user.accountId}>
          <UserInfo user={user} />
        </Paper>
      ))}
    </>
  )
}

SearchResults.propTypes = {
  completedSearchType: PropTypes.string,
  userDevices: PropTypes.arrayOf(PropTypes.shape({})),
  accountInfoWithDevicesAndServiceUsers: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
}

SearchResults.defaultProps = {
  completedSearchType: null,
  userDevices: null,
  accountInfoWithDevicesAndServiceUsers: null,
}

export default WithLoadingSpinner(SearchResults)
