import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import * as ROUTES from '../../constants/routes'
import { withFirebase } from '../Firebase'

import SignIn from './SignIn'


const SignInContainer = ({ firebase, history }) => {
  const [loginError, setLoginError] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = async data => {
    const auth = getAuth();
    return setPersistence(auth, browserSessionPersistence)
      .then(() => {
        firebase
          .doSignInWithEmailAndPassword(data.email, data.password)
          .then(() => {
            history.push(ROUTES.SEARCH)
            setLoginError(null)
          })
          .catch(error => {
            setLoginError({ error })
          })
      })
      .catch(error => {
        setLoginError({ error })
      })
  }

  return (
    <SignIn
      loginError={loginError}
      onSubmit={handleSubmit(onSubmit)}
      register={register}
      isSubmitting={isSubmitting}
    />
  )
}

SignInContainer.propTypes = {
  firebase: PropTypes.shape({
    doSignInWithEmailAndPassword: PropTypes.func,
    user: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired
}

SignInContainer.defaultProps = {
  firebase: {
    doSignInWithEmailAndPassword: () => { },
    user: () => { }
  },
}
export {SignInContainer as SignInContainerTest}
export default compose(
  withRouter,
  withFirebase,
)(SignInContainer)
