import React, { useContext, useState } from 'react'
import { compose } from 'recompose'
import { SnackbarContext } from '../common/Snackbar'
import { getUserAndAssociatedDevicesByName } from '../../api/index'
import { withAuthorization, withEmailVerification } from '../Session'
import SearchByName from "./SearchByName"
import { isAlphanumeric } from '../common/helpers/helpers'
import permissions from '../../constants/permissions'


const SearchByNameContainer = () => {
    const [accountInfoWithDevicesAndServiceUsers, setAccountInfoWithDevicesAndServiceUsers] = useState()
    const [inputValues, setInputValues] = useState({ FNInput: '', LNInput: '' })
    const [isLoading, setIsLoading] = useState(false)
    const [completedSearchType, setCompletedSearchType] = useState(null)
    const snackbar = useContext(SnackbarContext)

    const handleFirstNameChange = event => {
        setInputValues({ FNInput: event.target.value.trim(), LNInput: inputValues.LNInput })
    }

    const handleLastNameChange = event => {
        setInputValues({ LNInput: event.target.value.trim(), FNInput: inputValues.FNInput })
    }

    const searchByName = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        setAccountInfoWithDevicesAndServiceUsers(null) // clear out accounts
        let query;
        if (isAlphanumeric(inputValues.FNInput) || isAlphanumeric(inputValues.LNInput)) {
          if (inputValues.FNInput && inputValues.LNInput) {
            query = 'firstLastName'
          } else if (inputValues.FNInput) {
              query = 'firstName'
          } else if (inputValues.LNInput) {
              query = 'lastName'
          }

          if (query) {
            const userData = await getUserAndAssociatedDevicesByName(inputValues.FNInput, inputValues.LNInput, query)
            setAccountInfoWithDevicesAndServiceUsers(userData)
          } else {
            snackbar.openSnackbar('Not a valid name. Please check spelling and try again.')
          }
        }

        setCompletedSearchType(query)
        setIsLoading(false)
    }

    return (
        <SearchByName
            accountInfoWithDevicesAndServiceUsers={accountInfoWithDevicesAndServiceUsers}
            completedSearchType={completedSearchType}
            handleFirstNameChange={handleFirstNameChange}
            handleLastNameChange={handleLastNameChange}
            searchByName={searchByName}
            inputValues={inputValues}
            isLoading={isLoading}
        />
    )
}

export { SearchByNameContainer as SearchByNameContainerTest } // used to be able to test component directly
export default compose(
    withEmailVerification,
    withAuthorization([permissions.viewSearch]),
)(SearchByNameContainer)
