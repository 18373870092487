import React from 'react'
import {
  Box,
  Chip,
  Grid,
  List,
  Link,
  Avatar,
  Hidden,
  Button,
  Divider,
  ListItem,
  Typography,
  makeStyles,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { AccountCircleSharp, VideocamSharp } from '@material-ui/icons'

import SockIcon from './SockIcon'
import * as ROUTES from '../../../constants/routes'
import titleCase from '../../common/helpers/helpers'

const useStyles = makeStyles((theme) => ({
  name: { marginBottom: theme.spacing(2) },
  accountButton: { width: 250 },
}))

const UserInfo = ({ user }) => {
  const classes = useStyles()

  if (!user) {
    return null
  }

  const {
    email,
    region,
    lastName,
    firstName,
    accountId,
    phoneNumber,
    serviceUsers,
    serviceUsersDevices,
  } = user

  const devices = Object.values(serviceUsersDevices || {})

  let accountRoute = ROUTES.USERACCOUNT.replace(':accountId', accountId)

  if (serviceUsersDevices) {
    accountRoute = accountRoute
      .replace(':dsn', serviceUsersDevices[0].dsn)
      .replace(':serviceUserId', serviceUsersDevices[0].serviceUserId)
  } else if (serviceUsers) {
    accountRoute = accountRoute
      .replace(':dsn', serviceUsers[0].dsn)
      .replace(':serviceUserId', serviceUsers[0].serviceUserId)
  }

  return (
    <Box py={3} data-testid="userInfo">
      <ListItem alignItems="flex-start">
        <Hidden xsDown>
          <ListItemAvatar>
            <Avatar>
              <AccountCircleSharp />
            </Avatar>
          </ListItemAvatar>
        </Hidden>
        <ListItemText
          disableTypography
          primary={
            <Grid
              container
              alignItems="flex-start"
              className={classes.name}
              justifyContent="space-between"
            >
              <Grid item>
                <Box mb={2}>
                  <Grid container alignItems="center">
                    <Hidden smUp>
                      <Box mr={1} display="inline-flex">
                        <Avatar>
                          <AccountCircleSharp />
                        </Avatar>
                      </Box>
                    </Hidden>
                    <Typography variant="h3">
                      {firstName} {lastName}
                    </Typography>
                    <Box ml={1}>
                      <Chip label={region} size="small" />
                    </Box>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Typography color="textSecondary" variant="body2">
                    {email && <Link href={`mailTo:${email}`}>{email}</Link>}
                    {email && '| '}
                    {phoneNumber && (
                      <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
                    )}
                  </Typography>
                  <Typography display="inline" variant="body2">
                    Account ID:&nbsp;
                  </Typography>
                  <Typography
                    display="inline"
                    variant="body2"
                    color="textSecondary"
                  >
                    {accountId}
                  </Typography>
                </Box>
              </Grid>
              {accountRoute && (
                <Button
                  to={accountRoute}
                  color="secondary"
                  variant="contained"
                  component={RouterLink}
                  data-testid="accountLink"
                  className={classes.accountButton}
                >
                  View Account Details
                </Button>
              )}
            </Grid>
          }
          secondary={
            <>
              <Typography>
                {devices.length
                  ? `${devices.length} active devices`
                  : 'No Associated Devices'}
              </Typography>
              <Divider />
              <List>
                {devices.length &&
                  devices.map((device) => {
                    const deviceRoute = ROUTES.ACCOUNTDEVICE.replace(
                      ':accountId',
                      device.accountId,
                    ).replace(':dsn', device.dsn)

                    return (
                      <ListItem
                        button
                        disableGutters
                        key={device.dsn}
                        to={deviceRoute}
                        component={RouterLink}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {device.type &&
                              device.type.toLowerCase().includes('sock') && (
                                <SockIcon />
                              )}
                            {device.type &&
                              device.type.toLowerCase().includes('cam') && (
                                <VideocamSharp />
                              )}
                            {!device.type && '?'}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          disableTypography
                          primary={
                            <Grid container>
                              <Grid item>
                                <Box mr={2}>
                                  <Typography variant="h4" color="textPrimary">
                                    {device.type && titleCase(device.type)}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="body2"
                                  display="inline"
                                  color="textPrimary"
                                >
                                  DSN:{' '}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  display="inline"
                                  color="textSecondary"
                                >
                                  {device.dsn}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          secondary={
                            <>
                              <Typography
                                variant="body2"
                                display="inline"
                                color="textPrimary"
                              >
                                Registered:{' '}
                              </Typography>
                              <Typography
                                variant="body2"
                                display="inline"
                                color="textSecondary"
                              >
                                {device.registrationDate
                                  ? format(
                                      new Date(device.registrationDate),
                                      'LLLL dd yyyy',
                                    )
                                  : 'unknown'}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    )
                  })}
              </List>
            </>
          }
        />
      </ListItem>
    </Box>
  )
}

UserInfo.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    region: PropTypes.string,
    phoneNumber: PropTypes.string,
    accountId: PropTypes.string,
    serviceUsersDevices: PropTypes.shape({
      accountId: PropTypes.string,
      activatedDate: PropTypes.string,
      deviceId: PropTypes.string,
      dsn: PropTypes.string,
      firmwareVersion: PropTypes.string,
      name: PropTypes.string,
      registrationDate: PropTypes.string,
      serviceUserId: PropTypes.string,
      type: PropTypes.string,
      userProfile: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      length: PropTypes.number,
    }),
    serviceUsers: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      region: PropTypes.string,
      phoneNumber: PropTypes.string,
      accountId: PropTypes.string,
    }),
  }).isRequired,
}

export default UserInfo
