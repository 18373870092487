import { Divider } from '@material-ui/core'
import React from 'react'
import "../../DeviceOverview/Device.css"
import '../HelpPage.css'


const HelpAdminEmployeeList = () => {
    return (
        <div>
            <h2>Admin List Page:</h2>
            <p>
                This page contains a list of all employees that have signed up for
                an account to be used with this portal. This page is only
                available to admin and those with the correct permissions. Clicking
                anywhere in the employee field will take you to the employee details
                page.
            </p>
            <Divider />
            <h3>Manage Roles</h3>
            <p>
                Clicking on this button will allow you to manage, assign and
                create roles and manage permissions.
            </p>
            <Divider />
            <h3>+</h3>
            <p>Clicking the &ldquo;plus&rdquo; will allow you to create a new role.</p>
            <Divider />
            <h3>-</h3>
            <p>Selecting a role and clicking the &ldquo;minus&rdquo; will allow you to delete that selected role.</p>
            <Divider />
            <h3>Manage Permissions</h3>
            <p>
                Clicking on this button will allow you to manage and assign permissions.
            </p>
            <Divider />
            <h3>Permissons</h3>
            <p>
                A list of permissions available to assign to a role.
            </p>
        </div>
    )
}

export default HelpAdminEmployeeList
