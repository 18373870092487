import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import GoogleIcon from '../../common/GoogleButton/GoogleButton'
import { withFirebase } from '../../Firebase'
import '../Landing.css'


const SignInGoogleBase = ({ firebase }) => {
  const [error, setError] = useState(null)
  const ERROR_CODE_ACCOUNT_EXISTS =
    'auth/account-exists-with-different-credential'

  const ERROR_MSG_ACCOUNT_EXISTS = `
    An account with an E-Mail address to
    this social account already exists. Try to login from
    this account instead and associate your social accounts on
    your personal account page.
  `

  const onSubmit = event => {
    firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        const userData = {
          uid: socialAuthUser.user.uid,
          firstName: socialAuthUser.user.displayName
            .split(' ')
            .slice(0, -1)
            .join(' '),
          lastName: socialAuthUser.user.displayName
            .split(' ')
            .slice(-1)
            .join(' '),
          email: socialAuthUser.user.email,
        }

        if (socialAuthUser.additionalUserInfo.isNewUser) {
          firebase.user(socialAuthUser.user.uid).set(userData)
        }

        return true
      })
      .then(() => {
        setError(null)
      })
      .catch(submitError => {
        const errorContent = submitError
        if (errorContent.code === ERROR_CODE_ACCOUNT_EXISTS) {
          errorContent.message = ERROR_MSG_ACCOUNT_EXISTS
        }
        setError(errorContent)
      })

    event.preventDefault()
  }

  return (
    <form onSubmit={onSubmit}>
      <Button
        className="googleButton"
        type="submit"
        variant="contained"
        fullWidth
        color="primary"
      >
        <GoogleIcon type="dark" />
        Sign In with Google
      </Button>
      {error && <p>{error.message}</p>}
    </form>
  )
}

SignInGoogleBase.propTypes = {
  firebase: PropTypes.shape({
    doSignInWithGoogle: PropTypes.func,
    user: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired
}

export { SignInGoogleBase as SignInGoogleBaseTest } // used to be able to test component directly

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase)

export default SignInGoogle
