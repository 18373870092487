import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import * as ROUTES from '../../constants/routes'
import { withFirebase } from '../Firebase'

import SignUp from './SignUp'


const SignUpContainer = ({ firebase, history }) => {
  const [formError, setFormError] = useState(null)
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setError,
    clearError,
    formState: { isSubmitting },
  } = useForm()

  const password = useRef({})
  password.current = watch('password', '')

  const onSubmit = async data => {
    firebase
      .doCreateUserWithEmailAndPassword(data.email, data.password)
      .then(authUser => {
        firebase.user(authUser.user.uid).set({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        })
        setFormError(null)
        history.push(ROUTES.SEARCH)
      })
      .catch(error => {
        setFormError({ error })
      })
  }

  const clearFormError = () => {
    setFormError(null)
  }

  return (
    <SignUp
      onSubmit={handleSubmit(onSubmit)}
      register={register}
      errors={errors}
      setError={setError}
      clearError={clearError}
      isSubmitting={isSubmitting}
      watch={watch}
      password={password}
      formError={formError}
      clearFormError={clearFormError}
    />
  )
}

SignUpContainer.propTypes = {
  firebase: PropTypes.shape({
    doCreateUserWithEmailAndPassword: PropTypes.func,
    user: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired
}

SignUpContainer.defaultProps = {
  firebase: {
    doCreateUserWithEmailAndPassword: () => { },
    user: () => { }
  },
}
export {SignUpContainer as SignUpContainerTest}
export default compose(
  withRouter,
  withFirebase,
)(SignUpContainer)
