import { Link, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React, { Component, useContext } from 'react'

const SnackbarContext = React.createContext()

export class SnackbarProvider extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.shape(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      message: '',
      link: null,
      severity: 'info',
    }
  }

  openSnackbar = (message, link, severity = 'info') => {
    this.setState({
      message,
      link,
      severity,
      isOpen: true,
    })
  }

  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({
        isOpen: false,
      })
    }
  }

  render() {
    const { children } = this.props
    const { isOpen, message, link, severity } = this.state
    const action = link && (
      <Link
        href={link}
        target="_blank"
        rel="noreferrer"
        underline="hover"
        style={{
          color: 'white',
          marginLeft: '1rem',
          fontWeight: 'bolder',
          display: 'inline-block',
        }}
      >
        Link
      </Link>
    )

    return (
      <SnackbarContext.Provider
        value={{
          // eslint-disable-line react/jsx-no-constructed-context-values
          openSnackbar: this.openSnackbar,
          closeSnackbar: this.closeSnackbar,
          snackbarIsOpen: isOpen,
          snackMessage: message,
          snackSeverity: severity,
        }}
      >
        <SnackbarConsumer>
          {({ snackbarIsOpen, snackMessage, closeSnackbar, snackSeverity }) => (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={snackbarIsOpen}
              autoHideDuration={6000}
            >
              <Alert
                variant="filled"
                onClose={closeSnackbar}
                severity={snackSeverity}
              >
                {snackMessage}
                {action}
              </Alert>
            </Snackbar>
          )}
        </SnackbarConsumer>
        {children}
      </SnackbarContext.Provider>
    )
  }
}

export const SnackbarConsumer = SnackbarContext.Consumer

export default SnackbarContext

export const useSnackbar = () => useContext(SnackbarContext)
