import { Button, Grid, Container, Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import bg from '../../../assets/images/Background.png'

import SignInGoogle from './SignInGoogleBase'

import '../Landing.css'


const LandingCard = ({ handleCreateAccountCardShow, handleSignInCardShow }) => {
  return (
    <Grid
      className="landingCardContainer"
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src={bg} className="bg" alt="Landing Background" />
      <Card className="cardId">
        <Container>
          <h1>Welcome to the Owlet Portal</h1>
          <p>Sign in with Google or your email address</p>
          <Button
            onClick={handleCreateAccountCardShow}
            className="emptyBtn"
            type="submit"
            variant="outlined"
            margin="normal"
            fullWidth
          >
            Create New Account with Email
          </Button>
          <br />
          <br />

          <Button
            onClick={handleSignInCardShow}
            className="filledBtn"
            type="submit"
            variant="contained"
            margin="normal"
            fullWidth
          >
            Sign In with Email and Password
          </Button>
          <br />
          <br />
          <SignInGoogle />
        </Container>
      </Card>
    </Grid>
  )
}

LandingCard.propTypes = {
  handleCreateAccountCardShow: PropTypes.func.isRequired,
  handleSignInCardShow: PropTypes.func.isRequired
}

export default LandingCard
