import PropTypes from 'prop-types'
import React, { useContext, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import * as ROUTES from '../../constants/routes'
import { SnackbarContext } from '../common/Snackbar'
import { withFirebase } from '../Firebase'

import PasswordChange from './PasswordChange'


const PasswordChangeContainer = ({ firebase, history, handleAccountShow }) => {
  const [error, setError] = useState(null)
  const snackbar = useContext(SnackbarContext)

  const {
    errors,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm()

  const password = useRef({})
  password.current = watch('password', '')

  const onSubmit = async data => {
    firebase
      .doPasswordUpdate(data.password)
      .then(() => {
        setError(null)
        snackbar.openSnackbar('Password successfully updated')
        history.push(ROUTES.ACCOUNT)
        handleAccountShow()
      })
      .catch(err => {
        console.error(`Error updating password: ${err}`)
        setError({ err })
        snackbar.openSnackbar(
          'An error occured while updating password. Please try again.',
        )
      })
  }

  return (
    <PasswordChange
      error={error}
      errors={errors}
      password={password}
      onSubmit={handleSubmit(onSubmit)}
      register={register}
      isSubmitting={isSubmitting}
    />
  )
}

PasswordChangeContainer.propTypes = {
  firebase: PropTypes.shape({
    doPasswordUpdate: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  handleAccountShow: PropTypes.func
}

PasswordChangeContainer.defaultProps = {
  firebase: {
    doPasswordUpdate: () => { }
  },
  handleAccountShow: () => { }
}
export {PasswordChangeContainer as PasswordChangeContainerTest}
export default compose(
  withRouter,
  withFirebase,
)(PasswordChangeContainer)
