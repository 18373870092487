import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react'
import PropTypes from 'prop-types'

import { useRoles } from './RolesContext'
import { FirebaseContext } from '../../Firebase'
import { useSnackbar } from '../../common/Snackbar'

const EmployeeContext = createContext()

export default function Provider({ children }) {
  const firebase = useContext(FirebaseContext)

  const { roles } = useRoles()
  const snackbar = useSnackbar()
  const { db: database } = firebase
  const [uid, setUid] = useState(null)
  const [user, setUser] = useState(null)
  const [users, setUsers] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saveUser, setSaveUser] = useState(false)
  const [saveRoles, setSaveRoles] = useState(false)
  const [rolesUpdated, setRolesUpdated] = useState(false)
  const [initialUserName, setInitialUserName] = useState(null)
  const [initialUserRoles, setInitialUserRoles] = useState(null)

  const handleError = useCallback(
    (e) => {
      console.error(e)
      snackbar.openSnackbar(
        'An error ocurred. Please try again.',
        null,
        'error',
      )
    },
    [snackbar],
  )

  useEffect(() => {
    ;(async function load() {
      setLoading(true)
      const kvUsers = (await database.ref(`/users`).once('value')).val()
      const arrayUsers = Object.entries(kvUsers || {}).map(([k, v]) => ({
        ...v,
        uid: k,
        roles: Object.keys(v.roles || {}),
      }))

      setUsers(arrayUsers)

      if (uid) {
        const singleUser = arrayUsers.find((u) => `${u.uid}` === `${uid}`)
        if (singleUser) {
          setUser(singleUser)
          setInitialUserRoles([...singleUser.roles])
          setInitialUserName(`${singleUser.firstName} ${singleUser.lastName}`)
        }
      }

      setLoading(false)
    })()
  }, [database, uid])

  useEffect(() => {
    if (user?.roles && roles && initialUserRoles) {
      // The following assumes the roles are arrays of strings
      const same =
        user.roles?.sort().join(',') === initialUserRoles.sort().join(',')
      setRolesUpdated(!same)

      if (!same && saveRoles) {
        setLoading(true)
        const kvpRoles = user.roles.reduce((r, i) => ({ ...r, [i]: true }), {})
        ;(async function save() {
          await database
            .ref(`users/${uid}/roles`)
            .set(kvpRoles)
            .catch(handleError)
          await Promise.all(
            roles.map((role) => {
              return user.roles.includes(role.rid)
                ? database.ref(`roles/${role.rid}/users/${uid}`).set(true)
                : database.ref(`roles/${role.rid}/users/${uid}`).remove()
            }),
          ).catch(handleError)

          setLoading(false)
          setSaveRoles(false)
          setInitialUserRoles(user.roles) // importantly prevents infinite loop
          snackbar.openSnackbar('User roles updated')
        })()
      }
    }
  }, [
    uid,
    roles,
    database,
    snackbar,
    saveRoles,
    user?.roles,
    handleError,
    initialUserRoles,
  ])

  useEffect(() => {
    if (
      user &&
      saveUser &&
      initialUserName &&
      initialUserName !== `${user?.firstName} ${user?.lastName}`
    ) {
      ;(async function save() {
        await firebase
          .user(uid)
          .update({ firstName: user.firstName, lastName: user.lastName })
      })()
    }
  }, [user, firebase, uid, initialUserName, saveUser])

  async function triggerPasswordReset() {
    if (!user?.email) return false
    await firebase.doPasswordReset(user.email).catch(handleError)
    snackbar.openSnackbar('Password reset email sent')
    return true
  }

  async function deleteUser() {
    await firebase.user(uid).remove()
    await Promise.all(
      roles.map((role) => {
        return user.roles.includes(role.rid)
          ? database.ref(`roles/${role.rid}/users/${uid}`).remove()
          : Promise.resolve(false)
      }),
    ).catch(handleError)
    snackbar.openSnackbar('Deleted Successfully')
    setUser(null)
  }

  return (
    <EmployeeContext.Provider
      value={{
        user,
        users,
        roles,
        setUid,
        setUser,
        loading,
        deleteUser,
        setSaveUser,
        rolesUpdated,
        setSaveRoles,
        triggerPasswordReset,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useEmployee = () => useContext(EmployeeContext)
