import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { deleteSevenDaysData } from '../../../../api/lib/apiV1'
import '../../../common/Layout/Buttons.css'
import { SnackbarContext } from '../../../common/Snackbar'


const DeleteSevenDaysData = ({ currentDevice }) => {
  const snackbar = useContext(SnackbarContext)
  const { accountId, deviceId } = currentDevice



  const onNukeClick  = async () => {
    const result = await deleteSevenDaysData(accountId, deviceId)

    if (result) {
      snackbar.openSnackbar(
        `Delete 7 days data request sent successfully. Please wait a few minutes for sleep data to appear in app.`
      )
    } else {
      snackbar.openSnackbar(
        'Error sending Delete 7 days request. Please try again.'
      )
    }

  }


  return (
    <div>
      <Button
        className="emptyBtn emptySmaller"
        variant="outlined"
        onClick={onNukeClick}>
        Nuke 7 days Data
      </Button>
    </div>
  )
}


DeleteSevenDaysData.propTypes = {
  authUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.string
    ),
    roles: PropTypes.shape(PropTypes.string),
  }),
  currentDevice: PropTypes.shape({
    accountId: PropTypes.string,
    deviceId: PropTypes.string,
    dsn: PropTypes.string,
    type: PropTypes.string,
  }),
}

DeleteSevenDaysData.defaultProps = {
  authUser: {},
  currentDevice: {},
}

export default DeleteSevenDaysData
