import firebase from 'firebase/compat/app'
import React from 'react'

import { SnackbarContext } from '../common/Snackbar'
import { withAuthentication } from '../Session'

import App from './App'


class AppContainer extends React.Component {
  static contextType = SnackbarContext

  static logout() {
    // Send a logout request to the API
    firebase.auth().signOut()
  }

  constructor(props) {
    super(props)

    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ]

    this.warn = this.warn.bind(this)
    this.resetTimeout = this.resetTimeout.bind(this)

    Object.values(this.events).map(event => {
      // add listener for user events
      return window.addEventListener(event, this.resetTimeout)
    })

    this.setTimeout()
  }

  setTimeout() {
    // set timeout for auto logout to 2hrs
    this.logoutTimeout = setTimeout(AppContainer.logout, 2 * 60 * 60 * 1000)
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout)

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout)
  }

  resetTimeout() {
    this.clearTimeout()
    this.setTimeout()
  }

  warn() {
    const { context } = this
    context.openSnackbar('You will be logged out automatically in 1 minute.')
  }

  render() {
    return <App />
  }
}

export default withAuthentication(AppContainer)
