import PropTypes from 'prop-types'
import React from 'react'
import { Redirect } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'
import CreateAccountCard from './components/CreateAccountCard'
import LandingCard from './components/LandingCard'
import PasswordForgetCard from './components/PasswordForgetCard'
import SignInCard from './components/SignInCard'

import '../../assets/styles/FormWithValidationStyle.css'
import "../common/Layout/Buttons.css"
import './Landing.css'


const Landing = ({ authenticated, location, landingCard, createAccountCard, passwordForgetCard, handleLandingCardShow, handleCreateAccountCardShow, handleSignInCardShow, handlePasswordForgetCardShow }) => {

  if (authenticated) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.SEARCH,
          state: { from: location },
        }}
      />
    )
  }
  if (landingCard) {
    return <LandingCard handleCreateAccountCardShow={handleCreateAccountCardShow} handleSignInCardShow={handleSignInCardShow} />
  }
  if (createAccountCard) {
    return <CreateAccountCard handleLandingCardShow={handleLandingCardShow} />
  }
  if (passwordForgetCard) {
    return <PasswordForgetCard handleSignInCardShow={handleSignInCardShow} />
  }
  return <SignInCard handleCreateAccountCardShow={handleCreateAccountCardShow} handleSignInCardShow={handleSignInCardShow} handlePasswordForgetCardShow={handlePasswordForgetCardShow} handleLandingCardShow={handleLandingCardShow} />
}

Landing.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape({}),
    key: PropTypes.string
  }),
  landingCard: PropTypes.bool.isRequired,
  createAccountCard: PropTypes.bool.isRequired,
  passwordForgetCard: PropTypes.bool.isRequired,
  handleLandingCardShow: PropTypes.func,
  handleCreateAccountCardShow: PropTypes.func,
  handleSignInCardShow: PropTypes.func,
  handlePasswordForgetCardShow: PropTypes.func
}

Landing.defaultProps = {
  location: {
    pathname: "/",
    search: "",
    hash: "",
    state: undefined,
    key: "",
  },
  handleLandingCardShow: () => { },
  handleCreateAccountCardShow: () => { },
  handleSignInCardShow: () => { },
  handlePasswordForgetCardShow: () => { }
}

export default Landing
