import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Grid,
  Container,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import WithLoadingSpinner from '../common/LoadingSpinner/withLoadingSpinner'
import SearchResults from '../Search/components/SearchResults'
import '../common/Layout/Buttons.css'
import '../../index.css'
import '../Search/Search.css'
import '../common/Layout/lists.css'

const SearchByName = ({
  accountInfoWithDevicesAndServiceUsers,
  completedSearchType,
  handleFirstNameChange,
  handleLastNameChange,
  inputValues,
  isLoading,
  searchByName,
}) => {
  return (
    <>
      <Container>
        <Typography variant="h2" paragraph>
          Search By Name
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            sm={6}
            xs={12}
            component="form"
            autoComplete="off"
            className="searchForm"
            onSubmit={searchByName}
            data-testid="nameSearch"
          >
            <FormControl
              fullWidth
              size="medium"
              color="secondary"
              variant="outlined"
            >
              <InputLabel htmlFor="search-first-name">First Name</InputLabel>
              <OutlinedInput
                labelWidth={75}
                variant="outlined"
                id="search-first-name"
                value={inputValues.FNInput}
                onChange={handleFirstNameChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      value="firstName"
                      type="submit"
                      color="secondary"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            component="form"
            autoComplete="off"
            className="searchForm"
            onSubmit={searchByName}
            data-testid="nameSearch"
          >
            <FormControl
              fullWidth
              size="medium"
              color="secondary"
              variant="outlined"
            >
              <InputLabel htmlFor="search-last-name">Last Name</InputLabel>
              <OutlinedInput
                labelWidth={75}
                variant="outlined"
                id="search-last-name"
                value={inputValues.LNInput}
                onChange={handleLastNameChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      value="lastName"
                      type="submit"
                      color="secondary"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Box>
        <SearchResults
          accountInfoWithDevicesAndServiceUsers={
            accountInfoWithDevicesAndServiceUsers
          }
          completedSearchType={completedSearchType}
          loading={isLoading}
        />
      </Box>
    </>
  )
}

SearchByName.propTypes = {
  accountInfoWithDevicesAndServiceUsers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      region: PropTypes.string,
      phoneNumber: PropTypes.string,
      serviceUsersDevices: PropTypes.shape({
        accountId: PropTypes.string,
        activatedDate: PropTypes.string,
        deviceId: PropTypes.string,
        dsn: PropTypes.string,
        firmwareVersion: PropTypes.string,
        name: PropTypes.string,
        registrationDate: PropTypes.string,
        serviceUserId: PropTypes.string,
        type: PropTypes.string,
        userProfile: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    }),
  ),
  completedSearchType: PropTypes.string,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  inputValues: PropTypes.shape({
    FNInput: PropTypes.string,
    LNInput: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchByName: PropTypes.func.isRequired,
}

SearchByName.defaultProps = {
  accountInfoWithDevicesAndServiceUsers: null,
  completedSearchType: null,
}

export default WithLoadingSpinner(SearchByName)
