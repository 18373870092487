import React from 'react'
import {
  Box,
  Chip,
  Grid,
  List,
  Avatar,
  Button,
  Hidden,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { AccountCircleSharp, VideocamSharp } from '@material-ui/icons'

import SockIcon from './SockIcon'
import * as ROUTES from '../../../constants/routes'
import titleCase from '../../common/helpers/helpers'

const DeviceInfo = ({ device }) => {
  if (!device) return null

  const deviceRoute = ROUTES.ACCOUNTDEVICE.replace(':dsn', device.dsn).replace(
    ':accountId',
    device.accountId,
  )

  const accountRoute = ROUTES.USERACCOUNT.replace(
    ':accountId',
    device.accountId,
  )
    .replace(':dsn', device.dsn)
    .replace(':serviceUserId', device.serviceUserIds[0])

  return (
    <Box py={3} data-testid="deviceInfo">
      <ListItem alignItems="flex-start">
        <Hidden xsDown>
          <ListItemAvatar>
            <Avatar>
              {device.type && device.type.toLowerCase().includes('sock') && (
                <SockIcon data-testid="sockIcon" />
              )}
              {device.type && device.type.toLowerCase().includes('cam') && (
                <VideocamSharp data-testid="cameraIcon" />
              )}
              {!device.type && '?'}
            </Avatar>
          </ListItemAvatar>
        </Hidden>
        <ListItemText
          disableTypography
          primary={
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item>
                <Box mb={2}>
                  <Grid container alignItems="center">
                    <Hidden smUp>
                      <Box mr={1} display="inline-flex">
                        <Avatar>
                          <AccountCircleSharp />
                        </Avatar>
                      </Box>
                    </Hidden>
                    <Typography variant="h3">
                      {device.userProfile?.firstName || 'Not Assigned'}{' '}
                      {device.type && (
                        <Chip label={titleCase(device.type)} size="small" />
                      )}
                    </Typography>
                  </Grid>
                </Box>
                <Grid container spacing={4}>
                  <Grid item>
                    <Box mb={3}>
                      <Typography display="inline" variant="body2">
                        DSN:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.dsn}
                      </Typography>
                      <br />
                      <Typography display="inline" variant="body2">
                        Registered:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.registrationDate &&
                          format(
                            new Date(device.registrationDate),
                            'LLLL dd yyyy',
                          )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mb={3}>
                      <Typography display="inline" variant="body2">
                        Firmware:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.firmwareVersion}
                      </Typography>
                      <br />
                      <Typography display="inline" variant="body2">
                        Activated:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.activatedDate &&
                          format(
                            new Date(device.activatedDate),
                            'LLLL dd yyyy',
                          )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mb={3}>
                      <Typography display="inline" variant="body2">
                        Service User ID:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.serviceUserIds[0]}
                      </Typography>
                      <br />
                      <Typography display="inline" variant="body2">
                        Last Online:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.lastOnlineTime &&
                          format(
                            new Date(device.lastOnlineTime),
                            'LLLL dd yyyy',
                          )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mb={3}>
                      <Typography display="inline" variant="body2">
                        Ayla Environment:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.aylaEnvironment}
                      </Typography>
                      <br />
                      <Typography display="inline" variant="body2">
                        MAC Address:{' '}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body2"
                        color="textSecondary"
                      >
                        {device.macAddress}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {deviceRoute && (
                <Box mb={3}>
                  <Button
                    to={deviceRoute}
                    variant="contained"
                    component={RouterLink}
                    color="secondary"
                  >
                    View More Details
                  </Button>
                </Box>
              )}
            </Grid>
          }
          secondary={
            device.account && (
              <>
                <Typography>Account Info:</Typography>
                <Divider />
                <List>
                  <ListItem button component={RouterLink} to={accountRoute}>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountCircleSharp />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="h4" color="textPrimary">
                          {device.account.firstName} {device.account.lastName}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2">
                          {device.account.email}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </>
            )
          }
        />
      </ListItem>
    </Box>
  )
}

DeviceInfo.propTypes = {
  device: PropTypes.shape({
    registrationDate: PropTypes.string,
    activatedDate: PropTypes.string,
    accountId: PropTypes.string,
    dsn: PropTypes.string,
    type: PropTypes.string,
    macAddress: PropTypes.string,
    deviceId: PropTypes.string,
    lastOnlineTime: PropTypes.string,
    firmwareVersion: PropTypes.string,
    aylaEnvironment: PropTypes.string,
    serviceUserIds: PropTypes.arrayOf(PropTypes.string),
    settingsStatus: PropTypes.shape({
      settings: PropTypes.shape({
        soft_brick: PropTypes.number,
      }),
    }),
    userProfile: PropTypes.shape({
      firstName: PropTypes.string,
      lastname: PropTypes.string,
    }),
    account: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
}

export default DeviceInfo
