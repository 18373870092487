import { Button, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { withFirebase } from '../Firebase'
import '../common/Layout/Buttons.css'


const PasswordChange = ({ onSubmit, register, errors, password, isSubmitting }) => {
  return (
    <form className="formWithValidation" onSubmit={onSubmit}>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
            {...register('password', {
              required: true,
              minLength: 8,
            })}
          />
          {errors.password &&
            errors.password.type === 'required' && (
              <span className="errorMessages">Password is requried.</span>
            )}
          {errors.password &&
            errors.password.type === 'minLength' && (
              <span className="errorMessages">
                Password must have at least 8 characters.
              </span>
            )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            label="Confirm password"
            variant="outlined"
            fullWidth
            {...register('passwordConfirm', {
              required: true,
              validate: value =>
                value === password.current ||
                'The passwords do not match',
            })}
          />
          {errors.passwordConfirm &&
            errors.passwordConfirm.type === 'required' && (
              <span className="errorMessages">
                Confirm password is requried.
              </span>
            )}
          {errors.passwordConfirm &&
            errors.passwordConfirm.type === 'validate' && (
              <span className="errorMessages">
                {errors.passwordConfirm.message}.
              </span>
            )}
        </Grid>
      </Grid>
      <br />

      <Button
        className="filledBtn"
        variant="contained"
        fullWidth
        disabled={isSubmitting}
        type="submit"
      >
        Reset Password
      </Button>
    </form>
  )
}

PasswordChange.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  password: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errors: PropTypes.shape({
    password: PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string
    }),
    passwordConfirm: PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string
    })
  }),
  isSubmitting: PropTypes.bool.isRequired,
}

PasswordChange.defaultProps = {
  password: { current: '' },
  errors: {}
}

export default withFirebase(PasswordChange)
