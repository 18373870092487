import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import '../../index.css'
import Layout from '../common/Layout'
import HelpPage from '../HelpPage/HelpPage'
import { SnackbarProvider } from '../common/Snackbar'
import { AuthUserContext, withAuthentication } from '../Session'
import ErrorBoundary from '../common/ErrorBoundary/ErrorBoundary'

import Search from '../Search'
import AdminPage from '../Admin'
import AccountPage from '../Account'
import LandingPage from '../Landing'
import SearchByName from '../SearchByName'
import UserOverview from '../UserOverview'
import DeviceOverview from '../DeviceOverview'
import * as ROUTES from '../../constants/routes'

const App = () => {
  const authUser = useContext(AuthUserContext)

  return (
    <SnackbarProvider>
      <Router>
        <Layout>
          <ErrorBoundary>
            {authUser && <HelpPage key="HelpPage" style />}
            <Switch>
              <Route exact path={ROUTES.LANDING} component={LandingPage} />
              <Route exact path={ROUTES.USERACCOUNT} component={UserOverview} />
              <Route path={ROUTES.ADMIN}>
                <AdminPage authUser={authUser} />
              </Route>
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route exact path={ROUTES.SEARCH} component={Search} />
              <Route
                exact
                path={ROUTES.SEARCH_BY_NAME}
                component={SearchByName}
              />
              <Route
                exact
                path={ROUTES.ACCOUNTDEVICE}
                component={DeviceOverview}
              />
            </Switch>
          </ErrorBoundary>
        </Layout>
      </Router>
    </SnackbarProvider>
  )
}

export default withAuthentication(App)
