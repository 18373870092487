export default function titleCase(str) {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function getAgeInMonths(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  age = age * 12 + m;
  return age;
}

export function monthsToYears(birthdayInSecondsFromUnixEpoch) {
  const months = getAgeInMonths(birthdayInSecondsFromUnixEpoch * 1000)
  if (months <= 18) {
    return `${months} months`
  }
  const years = Math.floor(months / 12)
  const calculatedMonths = Math.floor(months % 12)
  if (calculatedMonths === 0) {
    return `${years} years`
  }
  return `${years} years ${calculatedMonths} months`
}

export const requirePermissions = (permissions, authUser) => {
  if (authUser && authUser.permissions) {
    return permissions.every(permission => authUser.permissions.includes(permission))
  }
  return false
}

export function isAlphanumeric(str){
    if (typeof str !== 'string') {
      throw new TypeError('Expected a string');
    }

    return !/[^0-9a-z\xDF-\xFF]/.test(str.toLowerCase());
}
