import { Divider } from '@material-ui/core'

import React from 'react'
import "../../DeviceOverview/Device.css"
import '../HelpPage.css'


const HelpAccount = () => {
    return (
        <div>
            <h2>Account Page:</h2>
            <p>
                This page contains information about your account. It shows your
                name, email and UID.
            </p>
            <Divider />
            <h3>Name</h3>
            <p>
                Your name.
            </p>
            <Divider />
            <h3>Email</h3>
            <p>
                The email that you have used to login to this site.
            </p>
            <Divider />
            <h3>UID</h3>
            <p>
                Your unique user ID. This can be used to help troubleshoot
                problems with your account information in Firebase.
            </p>
            <Divider />
            <h3>Social Login Toggle</h3>
            <p>
                Clicking on this button will connect your account to your Google
                credentials so that you can use 1-click sign in if you did not
                sign up using 1-click sign in.
            </p>
            <Divider />
            <h3>Change Password</h3>
            <p>
                Clicking this button will allow you to enter and confirm a new
                password for your account.
            </p>
        </div>
    )
}

export default HelpAccount
