import API from '../../axiosInstance'


const serviceUsersAPI = {
    getServiceUsers: async (accountId) => {
        try {
            const result = await API().get(`/accounts/${accountId}/serviceUsers`) // returns array of {serviceUsers}
            return result.data
        } catch (error) {
            console.error(error)
            return null
        }
    },

    getServiceUserBySUID: async (accountId, suid) => {
        try {
            const result = await API().get(`/accounts/${accountId}/serviceUsers/${suid}`)
            return result.data
        } catch (error) {
            console.error(error)
            return null
        }
    }

    // TODO: These endpoints are to be used in later iterations of the backend

    // createServiceUser: async (accountKey) => {
    //     try {
    //         const result = await API().post(`/accounts/${accountKey}/serviceUsers`) // returns 501 not implemented
    //         return result
    //     } catch (error) {
    //         return isDevelopment(error, serviceUsersData)
    //     }
    // },
    // getServiceUsersWithoutAccountKey: async () => {
    //     try {
    //         const result = await API().get('/accounts/-/serviceUsers') // returns array of {serviceUsers}
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, serviceUsersData)
    //     }
    //     return {}
    // },
    // getServiceUser: async (accountKey, serviceUser) => {
    //     try {
    //         const result = await API().get(`/accounts/${accountKey}/serviceUsers/${serviceUser}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, serviceUsersData)
    //     }
    //     return {}
    // },
    // putServiceUser: async (accountKey, serviceUser) => {
    //     try {
    //         const result = await API().put(`/accounts/${accountKey}/serviceUsers/${serviceUser}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, serviceUsersData)
    //     }
    //     return {}
    // },
    // patchServiceUser: async (accountKey, serviceUser) => {
    //     try {
    //         const result = await API().patch(`/accounts/${accountKey}/serviceUsers/${serviceUser}`) // returns 501 not implemented
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, serviceUsersData)
    //     }
    //     return {}
    // },
    // deleteServiceUser: async (accountKey, serviceUser) => {
    //     try {
    //         const result = await API().delete(`/accounts/${accountKey}/serviceUsers/${serviceUser}`) // returns 501 not implemented
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, serviceUsersData)
    //     }
    //     return {}
    // },
}

export default serviceUsersAPI
