import PropTypes from 'prop-types'
import React from 'react'

import { withFirebase } from '../Firebase'

import AuthUserContext from './context'


const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        authUser: JSON.parse(sessionStorage.getItem('authUser')),
      }
    }

    componentDidMount() {
      const { firebase } = this.props
      this.listener = firebase.onAuthUserListener(
        authUser => {
          sessionStorage.setItem('authUser', JSON.stringify(authUser))
          this.setState({ authUser })
        },
        () => {
          sessionStorage.removeItem('authUser')
          this.setState({ authUser: null })
        },
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      const { authUser } = this.state
      WithAuthentication.propTypes = {
        firebase: PropTypes.shape({
          onAuthUserListener: PropTypes.func
        }).isRequired
      }
      return (
        <AuthUserContext.Provider value={authUser}>
          {/* eslint-disable-next-line */}
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}



export default withAuthentication
