import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import '../../../common/Layout/Buttons.css'


const unpairModal = ({
    currentDevice,
    hideUnpairModal,
    isUnpairModalShown,
    showUnpairModal,
    unpairSensor,
}) => {
    return (
        <>
            <Button
                className="emptyBtn emptySmaller"
                onClick={showUnpairModal}
                variant="outlined"
            >
                Unpair
            </Button>
            <Dialog
                onClose={hideUnpairModal}
                open={isUnpairModalShown}
            >
                <IconButton
                    aria-label="close"
                    className="modalCloseBtn"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onClick={hideUnpairModal}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>Unpair Base Station and Sensor?</DialogTitle>
                <DialogContent>
                    <p>Please make sure that the sensor is unplugged from the base station before continuing.</p>
                    <DialogActions>
                        <Button className="emptyBtn emptySmaller" variant="outlined" onClick={() => unpairSensor(currentDevice.accountId, currentDevice.dsn)}>Unpair</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

unpairModal.propTypes = {
    currentDevice: PropTypes.shape({
        accountId: PropTypes.string,
        dsn: PropTypes.string,
        serviceUserId: PropTypes.string,
    }),
    hideUnpairModal: PropTypes.func.isRequired,
    isUnpairModalShown: PropTypes.bool.isRequired,
    showUnpairModal: PropTypes.func.isRequired,
    unpairSensor: PropTypes.func.isRequired,
}

unpairModal.defaultProps = {
    currentDevice: {}
}

export default unpairModal
