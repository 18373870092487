import React from 'react'
import {
  Box,
  Grid,
  List,
  Paper,
  Avatar,
  Hidden,
  Button,
  ListItem,
  makeStyles,
  Typography,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import PasswordChangeForm from '../PasswordChange'
import LoginManagement from './components/LoginManagementBase'

const useStyles = makeStyles(() => ({
  changePassword: { width: 230 },
}))

const Account = ({
  error,
  account,
  history,
  authUser,
  firebase,
  onUnlink,
  passwordChange,
  SIGN_IN_METHODS,
  handleAccountShow,
  onSocialLoginLink,
  activeSignInMethods,
  handleSetPasswordChangeShow,
}) => {
  const classes = useStyles()

  if (!account) {
    if (!passwordChange) return null
    return (
      <Box mx={3}>
        <Typography variant="h2">Change Password</Typography>
        <Box m={2} mb={3}>
          <PasswordChangeForm
            firebase={firebase}
            history={history}
            handleAccountShow={handleAccountShow}
          />
        </Box>
        <Grid container justifyContent="center">
          <Button color="secondary" onClick={handleAccountShow}>
            Cancel
          </Button>
        </Grid>
      </Box>
    )
  }
  return (
    <Box mx={3}>
      <Box component={Typography} mb={2} variant="h2">
        Account
      </Box>
      <Paper component={List}>
        <Box m={2} component={ListItem} alignItems="flex-start">
          <Hidden xsDown>
            <ListItemAvatar>
              <Avatar src={authUser.providerData?.[0].photoURL} />
            </ListItemAvatar>
          </Hidden>
          <Box mx={1} component={ListItemText}>
            <Typography variant="h3">{`${authUser.firstName} ${authUser.lastName}`}</Typography>
            <Box my={3}>
              <Typography display="inline" variant="body2">
                Email:{' '}
              </Typography>
              <Typography
                display="inline"
                variant="body2"
                color="textSecondary"
              >
                {authUser.email}
              </Typography>
              <br />
              <Typography display="inline" variant="body2">
                UID:{' '}
              </Typography>
              <Typography
                display="inline"
                variant="body2"
                color="textSecondary"
              >
                {authUser.uid}
              </Typography>
            </Box>
            <Box mb={2}>
              <Button
                size="medium"
                type="button"
                color="secondary"
                component={Button}
                variant="contained"
                className={classes.changePassword}
                onClick={handleSetPasswordChangeShow}
              >
                Change Password
              </Button>
            </Box>
            <Box mb={2}>
              <LoginManagement
                onUnlink={onUnlink}
                onLink={onSocialLoginLink}
                SIGN_IN_METHODS={SIGN_IN_METHODS}
                activeSignInMethods={activeSignInMethods}
                error={error}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

Account.propTypes = {
  account: PropTypes.bool.isRequired,
  passwordChange: PropTypes.bool.isRequired,
  handleAccountShow: PropTypes.func.isRequired,
  handleSetPasswordChangeShow: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    providerData: PropTypes.arrayOf(
      PropTypes.shape({
        photoURL: PropTypes.string,
      }),
    ),
  }).isRequired,
  firebase: PropTypes.shape({
    doPasswordUpdate: PropTypes.func,
  }),
  history: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  SIGN_IN_METHODS: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      provider: PropTypes.string,
    }),
  ).isRequired,
  activeSignInMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
  }),
  onUnlink: PropTypes.func.isRequired,
  onSocialLoginLink: PropTypes.func.isRequired,
}

Account.defaultProps = {
  firebase: {
    doPasswordUpdate: () => {},
  },
  history: {},
  error: { code: 'None', message: 'No Error' },
}

export default Account
