import PropTypes from 'prop-types'
import React from 'react'
import '../common/Layout/Buttons.css'

import { withFirebase } from '../Firebase'


const SignOut = ({ firebase }) => <button type="button" className="signOutBtn" onClick={firebase.doSignOut}>Sign Out</button>

SignOut.propTypes = {
    firebase: PropTypes.shape({
        doSignOut: PropTypes.func,
    }).isRequired,
}

SignOut.propTypes = {
    firebase: PropTypes.shape({
        doSignOut: PropTypes.func,
    }).isRequired,
}
export {SignOut as SignOutTest}
export default withFirebase(SignOut)
