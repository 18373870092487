const permissions = {
  viewUserOverview: 'viewUserOverview',
  viewSearch: 'viewSearch',
  viewDeviceOverview: 'viewDeviceOverview',
  viewOMPManagement: 'viewOMPManagement',
  viewDeviceActions: 'viewDeviceActions',
  editDeviceActions: 'editDeviceActions',
  deleteDeviceActions: 'deleteDeviceActions',
  exportOMPManagement: 'exportOMPManagement'
}

export default permissions
