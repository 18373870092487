import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import * as ROUTES from '../../constants/routes'
import { SnackbarContext } from '../common/Snackbar'
import { withFirebase } from '../Firebase'

import AuthUserContext from './context'

import { requirePermissions } from '../common/helpers/helpers'

/**
 * @param {string[]} permissions=[]
 * @returns {function(*): *}
 */
const withAuthorization = (permissions = []) => Component => {

  class WithAuthorization extends React.Component {

    static contextType = SnackbarContext

    componentDidMount() {
      const { context } = this
      const { firebase, history } = this.props
      this.listener = firebase.onAuthUserListener(
        authUser => {
          if (!!authUser && !requirePermissions(permissions, authUser)) {
            context.openSnackbar(
              'Please follow the link to request permissions.',
              'https://owletcare.atlassian.net/servicedesk/customer/portal/2/group/12/create/168'
            )
          }
        },
        () => history.push(ROUTES.LANDING),
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      WithAuthorization.propTypes = {
        firebase: PropTypes.shape({
          onAuthUserListener: PropTypes.func,
        }),
        history: PropTypes.shape({
          push: PropTypes.func
        }).isRequired
      }
      WithAuthorization.defaultProps = {
        firebase: {
          onAuthUserListener: () => { },
        },
      }
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            // eslint-disable-next-line
            requirePermissions(permissions, authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      )
    }
  }


  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization)
}



export default withAuthorization
