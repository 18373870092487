import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function SockIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g className="sock">
        <path d="M203.1068,268.117l-60.8937,60.89a80.6011,80.6011,0,0,0,113.9894,113.985L340.7,358.4978a80.6177,80.6177,0,0,0,23.6042-56.9946V168.25H203.1068Z" />
        <rect
          height="96.525"
          rx="29.0886"
          width="219.375"
          x="174.0182"
          y="45.4"
        />
      </g>
    </SvgIcon>
  )
}
