import { Button, Grid, Container, Card, IconButton } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import React from 'react'

import bg from '../../../assets/images/Background.png'
import SignInForm from '../../SignIn'


const SignInCard = ({ handleLandingCardShow, handleCreateAccountCardShow, handlePasswordForgetCardShow, handleSignInCardShow }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src={bg} className="bg" alt="Landing Background" />
      <div>
        <Card className="cardId">
          <div className="backButton">
            <IconButton onClick={handleLandingCardShow}>
              <ArrowBack />
            </IconButton>
          </div>
          <Container>
            <h1>Sign In</h1>
            <SignInForm handleSignInCardShow={handleSignInCardShow} />
            <Button onClick={handleCreateAccountCardShow} className="noAcctBtn">
              Don&rsquo;t have an account? Sign Up
            </Button>
            <br />
            <Button onClick={handlePasswordForgetCardShow} className="noAcctBtn">
              Forgot Password?
            </Button>
          </Container>
        </Card>
      </div>
    </Grid>
  )
}

SignInCard.propTypes = {
  handleLandingCardShow: PropTypes.func.isRequired,
  handleCreateAccountCardShow: PropTypes.func.isRequired,
  handlePasswordForgetCardShow: PropTypes.func.isRequired,
  handleSignInCardShow: PropTypes.func.isRequired
}

export default SignInCard
