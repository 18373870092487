import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import RolesContext from './contexts/RolesContext'
import EmployeeContext from './contexts/EmployeeContext'
import RolesManagement from './components/RolesManagement'
import EmployeeUserItem from './components/EmployeeUserItem'
import EmployeeUserList from './components/EmployeeUserList'

const Admin = ({ authUser }) => {

  if (!authUser) return <Redirect to="/" />

  return (
    <RolesContext>
      <EmployeeContext>
        <Switch>
          <Route
            exact
            path={ROUTES.ADMIN}
            render={() => <EmployeeUserList authUser={authUser} />}
          />
          <Route
            path={ROUTES.ADMIN_DETAILS}
            render={() => <EmployeeUserItem />}
          />
          <Route
            exact
            path={ROUTES.ADMIN_ROLES}
            render={() => <RolesManagement />}
          />
        </Switch>
      </EmployeeContext>
    </RolesContext>
  )
}

Admin.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Admin
