import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { format } from 'date-fns'
import * as ROUTES from '../../constants/routes'
import WithLoadingSpinner from '../common/LoadingSpinner/withLoadingSpinner'
import AssociatedDevices from './components/AssociatedDevices'
import NotificationsHistory from './components/NotificationsHistory'
import DeviceActions from './components/DeviceActions/DeviceActions'
import '../../index.css'
import "./Device.css"
import '../common/Layout/lists.css'


const DeviceOverview = ({
  areUserDevicesLoading,
  authUser,
  currentDevice,
  handleChange,
  hideUnpairModal,
  hideUnregisterModal,
  isDeviceLoading,
  isUnpairModalShown,
  isUnregisterModalShown,
  selectedDeviceTabId,
  showUnpairModal,
  showUnregisterModal,
  unpairSensor,
  unregisterDeviceFromUser,
  userDevices,
}) => {
  if (isDeviceLoading && areUserDevicesLoading) {
    return null
  }

  const batteryStatusLabels = []

  /* eslint-disable no-bitwise */
  if (currentDevice.type.includes('smartSock3Medical')) {
    const baseStationBattery = {
      isSafetyTimerFault: (currentDevice.batteryStatus & 0b1) !== 0,
      isOVPFault: (currentDevice.batteryStatus & 0b10) !== 0,
      isMCUFault: (currentDevice.batteryStatus & 0b100) !== 0,
      isThermalShutdown: (currentDevice.batteryStatus & 0b1 << 3) !== 0,
      isFaultCold: (currentDevice.batteryStatus & 0b1 << 4) !== 0,
      isFaultHot: (currentDevice.batteryStatus & 0b1 << 5) !== 0,
      isWatchdogFault: (currentDevice.batteryStatus & 0b1 << 6) !== 0,
      isMCUInitializationFault: (currentDevice.batteryStatus & 0b1 << 7) !== 0,
      isNotCharging: (((currentDevice.batteryStatus & 0b1 << 8) === 0) && ((currentDevice.batteryStatus & 0b1 << 9) === 0)),
      isPreCharge: (((currentDevice.batteryStatus & 0b1 << 8) !== 0) && ((currentDevice.batteryStatus & 0b1 << 9) === 0)),
      isCharging: (((currentDevice.batteryStatus & 0b1 << 8) === 0) && ((currentDevice.batteryStatus & 0b1 << 9) !== 0)),
      isChargeComplete: (((currentDevice.batteryStatus & 0b1 << 8) !== 0) && ((currentDevice.batteryStatus & 0b1 << 9) !== 0)),
      isPreviewBit: (currentDevice.batteryStatus & 0b1 << 10) !== 0,
    }

    const sensorBattery = {
      isFault: (currentDevice.batteryStatus & 0b1 << 16) !== 0,
    }

    if (baseStationBattery.isSafetyTimerFault) {
      batteryStatusLabels.push("Base station battery has been charging too long without reaching charged status.")
    }
    if (baseStationBattery.isOVPFault) {
      batteryStatusLabels.push("Base station battery overvoltage threshold is crossed; can occur in a malfunctioning cell.")
    }
    if (baseStationBattery.isMCUFault) {
      batteryStatusLabels.push("MCU calculated fault bit.")
    }
    if (baseStationBattery.isThermalShutdown) {
      batteryStatusLabels.push("Base station battery junction temperature exceeded 80 degrees C and required IC shutdown.")
    }
    if (baseStationBattery.isFaultCold) {
      batteryStatusLabels.push("Base station battery temperature is too cold.")
    }
    if (baseStationBattery.isFaultHot) {
      batteryStatusLabels.push("Base station battery temperature is too hot.")
    }
    if (baseStationBattery.isWatchdogFault) {
      batteryStatusLabels.push("Base station battery watchdog fault in MP2665A.")
    }
    if (baseStationBattery.isMCUInitializationFault) {
      batteryStatusLabels.push("Communication with base station battery charge controller failed.")
    }
    if (baseStationBattery.isNotCharging) {
      batteryStatusLabels.push("Base station battery is not charging.")
    }
    if (baseStationBattery.isPreCharge) {
      batteryStatusLabels.push("Base station battery is pre-charging.")
    }
    if (baseStationBattery.isCharging) {
      batteryStatusLabels.push("Base station battery is charging.")
    }
    if (baseStationBattery.isChargeComplete) {
      batteryStatusLabels.push("Base station battery charging is complete.")
    }
    if (sensorBattery.isFault) {
      batteryStatusLabels.push("Sensor battery capacity has been degraded due to age, use, or other issue.")
    }
  }
  return (
    <div>
      <BreadcrumbsItem to={ROUTES.ACCOUNTDEVICE}>
        {currentDevice.dsn}
      </BreadcrumbsItem>
      <Table className="deviceTable">
        <TableBody>
          <TableRow>
            <TableCell className="deviceCell">
              <h1>Device Overview</h1>
            </TableCell >
            <TableCell className="deviceCell" />
            <TableCell className="deviceCell">
              <h1>Other Associated Devices</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sm={6} className="deviceCell">
              <Paper>
                <dl className="defList">
                  <dt>DSN</dt>
                  <dd>{currentDevice.dsn}</dd>

                  <dt>Type</dt>
                  <dd>{currentDevice.type}</dd>

                  <dt>Associated User</dt>
                  <dd>{currentDevice.account.firstName} {currentDevice.account.lastName}</dd>

                  <dt>Email</dt>
                  <dd><Link to={ROUTES.USERACCOUNT.replace(':accountId', currentDevice.accountId).replace(':serviceUserId', currentDevice.serviceUserIds[0]).replace(':dsn', currentDevice.dsn)} className="links">{currentDevice.account.email}</Link></dd>

                  <dt>Assigned To</dt>
                  <dd>{currentDevice.userProfile.firstName}</dd>

                  {currentDevice.aylaEnvironment ?
                    <>
                      <dt>Ayla Environment</dt>
                      <dd>{currentDevice.aylaEnvironment}</dd>
                    </>
                    : null}

                  {currentDevice.macAddress ?
                    <>
                      <dt>MAC Address</dt>
                      <dd>{currentDevice.macAddress}</dd>
                    </>
                    : null}
                </dl>
              </Paper>
            </TableCell>
            <TableCell sm={6} className="deviceCell">
              <Paper>
                <dl className="defList">
                  <dt>Activated Date</dt>
                  <dd>{currentDevice.activatedDate ? format(new Date(currentDevice.activatedDate), 'LLLL dd yyyy') : "Not Available"}</dd>

                  <dt>Registration Date</dt>
                  <dd>{currentDevice.registrationDate ? format(new Date(currentDevice.registrationDate), 'LLLL dd yyyy') : "Not Available"}</dd>

                  <dt>Firmware Version</dt>
                  <dd>{currentDevice.firmwareVersion}</dd>

                  {currentDevice.type.includes('smartSock') ?
                    <>
                      {currentDevice.type.includes('smartSock3') ?
                        <>
                          <dt>Firmware Update Status</dt>
                          <dd>{currentDevice.firmwareUpdateStatus}</dd>

                          {currentDevice.firmwareVersion !== currentDevice.runningFirmwareVersion ?
                            <>
                              <dt>Running Firmware Version</dt>
                              <dd>{currentDevice.runningFirmwareVersion}</dd>
                            </>
                            : null}
                        </>
                        : null}

                      <dt>OEM Sock Version</dt>
                      <dd>{currentDevice.oemSockVersion}</dd>

                      <dt>OEM Base Version</dt>
                      <dd>{currentDevice.oemBaseVersion}</dd>

                      {currentDevice.type.includes('smartSock3Medical') ?
                        <>
                          <dt>Battery Status</dt>
                          <dd>{batteryStatusLabels.length === 0 ? "Not available" : batteryStatusLabels.join(' ')}</dd>
                        </>
                        : null}
                    </>
                    : null}
                  <dt>Device Key</dt>
                  <dd>{currentDevice.deviceId}</dd>

                  {currentDevice.settingsStatus && currentDevice.settingsStatus.settings && currentDevice.settingsStatus.settings.soft_brick ?
                    <>
                      <dt>Soft Bricked</dt>
                      {
                        currentDevice.settingsStatus.settings.soft_brick === 1 ?
                          <dd className="softBrickStatus">{currentDevice.settingsStatus.settings.soft_brick} - Yes - Replace Sensor</dd> :
                          <dd className="softBrickStatus">{currentDevice.settingsStatus.settings.soft_brick} - No</dd>
                      }
                    </>
                    : null}

                  <dt>Service User</dt>
                  <dd>{currentDevice.serviceUserIds[0]} {currentDevice.serviceUserIds.length > 1 ? <span className="associatedServiceUsersNumber">[+{currentDevice.serviceUserIds.length - 1}]</span> : null}</dd>

                  <dt>Last Online</dt>
                  <dd>{currentDevice.lastOnlineTime ? format(new Date(currentDevice.lastOnlineTime), 'LLLL dd yyyy h:m a') : "Not Available"}</dd>
                </dl>
              </Paper>
            </TableCell>
            <TableCell sm={6} className="deviceCell">
              <AssociatedDevices
                areUserDevicesLoading={areUserDevicesLoading}
                handleChange={handleChange}
                selectedDeviceTabId={selectedDeviceTabId}
                userDevices={userDevices}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <NotificationsHistory
        accountId={currentDevice.accountId}
        deviceId={currentDevice.deviceId}
        deviceType={currentDevice.type}
      />
      <DeviceActions
        authUser={authUser}
        currentDevice={currentDevice}
        hideUnpairModal={hideUnpairModal}
        isUnpairModalShown={isUnpairModalShown}
        showUnpairModal={showUnpairModal}
        unpairSensor={unpairSensor}
        hideUnregisterModal={hideUnregisterModal}
        isUnregisterModalShown={isUnregisterModalShown}
        showUnregisterModal={showUnregisterModal}
        unregisterDeviceFromUser={unregisterDeviceFromUser}
      />
    </div>
  )
}

DeviceOverview.propTypes = {
  areUserDevicesLoading: PropTypes.bool,
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  currentDevice:
    PropTypes.shape({
      account: PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      accountId: PropTypes.string,
      activatedDate: PropTypes.string,
      associatedServiceUsers: PropTypes.arrayOf(PropTypes.shape({})),
      aylaEnvironment: PropTypes.string,
      deviceId: PropTypes.string,
      dsn: PropTypes.string,
      firmwareUpdateStatus: PropTypes.string,
      firmwareVersion: PropTypes.string,
      lastOnlineTime: PropTypes.string,
      macAddress: PropTypes.string,
      oemModel: PropTypes.string,
      oemBaseVersion: PropTypes.string,
      oemSockVersion: PropTypes.string,
      registrationDate: PropTypes.string,
      runningFirmwareVersion: PropTypes.string,
      serviceUserId: PropTypes.string,
      serviceUserIds: PropTypes.arrayOf(PropTypes.string),
      settingsStatus: PropTypes.shape({
        settings: PropTypes.shape({
          soft_brick: PropTypes.number,
        })
      }),
      type: PropTypes.string,
      userProfile: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      }),
      batteryStatus: PropTypes.number,
    }),
  userDevices: PropTypes.arrayOf(
    PropTypes.shape({
      activatedDate: PropTypes.string,
      aylaEnvironment: PropTypes.string,
      deviceKey: PropTypes.string,
      dsn: PropTypes.string,
      firmwareVersion: PropTypes.string,
      lastOnlineTime: PropTypes.string,
      macAddress: PropTypes.string,
      registrationDate: PropTypes.string,
      serviceUserId: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  handleChange: PropTypes.func.isRequired,
  hideUnpairModal: PropTypes.func.isRequired,
  hideUnregisterModal: PropTypes.func.isRequired,
  isDeviceLoading: PropTypes.bool,
  isUnpairModalShown: PropTypes.bool.isRequired,
  isUnregisterModalShown: PropTypes.bool.isRequired,
  selectedDeviceTabId: PropTypes.string.isRequired,
  showUnpairModal: PropTypes.func.isRequired,
  showUnregisterModal: PropTypes.func.isRequired,
  unpairSensor: PropTypes.func.isRequired,
  unregisterDeviceFromUser: PropTypes.func.isRequired,
}

DeviceOverview.defaultProps = {
  areUserDevicesLoading: false,
  currentDevice: {
    account: {
      firstName: "",
      email: ""
    },
    ayla_created_at: "",
    aylaEnvironment: "",
    created_at: "",
    deviceId: "",
    dsn: "",
    firmwareUpdateStatus: "",
    firmwareVersion: "",
    lastOnlineTime: "",
    macAddress: "",
    oemModel:"",
    oemBaseVersion: "",
    oemSockVersion: "",
    registered_at: "",
    registrationDate: "",
    runningFirmwareVersion: "",
    serviceUserIds: [],
    type: "",
    userProfile: {
      firstName: "",
      lastName: ""
    },
    batteryStatus: 0,
  },
  isDeviceLoading: false,
  userDevices: [{
    ayla_created_at: "",
    aylaEnvironment: "",
    created_at: "",
    deviceId: "",
    dsn: "",
    firmwareVersion: "",
    lastOnlineTime: "",
    macAddress: "",
    registered_at: "",
    registrationDate: "",
    serviceUserId: "",
    type: "",
  }],

}

export default WithLoadingSpinner(DeviceOverview)
