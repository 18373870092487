import React, { useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { generateSevenDaysData } from '../../../../api/lib/apiV1'
import '../../../common/Layout/Buttons.css'
import { SnackbarContext } from '../../../common/Snackbar'


const GenerateSevenDaysData = ({ currentDevice }) => {
  const snackbar = useContext(SnackbarContext)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const { accountId, dsn, deviceId } = currentDevice

  const getLocalTimezoneOffsetISO = () => {
    const date = new Date();
    const offsetInMinutes = date.getTimezoneOffset();
    const sign = offsetInMinutes <= 0 ? '+' : '-';
    const absOffsetInMinutes = Math.abs(offsetInMinutes);
    const hours = String(Math.floor(absOffsetInMinutes / 60)).padStart(2, '0');
    const minutes = String(absOffsetInMinutes % 60).padStart(2, '0');
    return `${sign}${hours}:${minutes}`;
  }

  const timezone = getLocalTimezoneOffsetISO();
  const isoDatePart = new Date().toLocaleDateString('en-CA');

  const defaultTemplate =
    `{
      "account_id": "${accountId}",
      "dsn": "${dsn}",
      "oem_model": "SS3-Sleep",
      "job_id": "${accountId}-${dsn}",
      "today": "${isoDatePart}",
      "history_data": {
        "1": [

        ],
        "2": [

        ],
        "3": [

        ],
        "4": [

        ],
        "5": [

        ],
        "6": [
          {
            "start_time": "22:00:00${timezone}",
            "sleep_length": 300,
            "temp_range": [76, 80],
            "hr_range": [90,100],
            "movement_range": [0,26],
            "ox_range": [95,100]
          }
        ],
        "7": [
          {
            "start_time": "03:00:00-05:00",
            "sleep_length": 60,
            "temp_range": [76, 80],
            "hr_range": [90,100],
            "movement_range": [0,5],
            "ox_range": [95,100]
          },
        {
            "start_time": "04:00:00-05:00",
            "sleep_length": 30,
            "temp_range": [76, 80],
            "hr_range": [90,100],
            "movement_range": [25,26],
            "ox_range": [95,100]
          },{
            "start_time": "04:30:00-05:00",
            "sleep_length": 30,
            "temp_range": [76, 80],
            "hr_range": [90,100],
            "movement_range": [0,24],
            "ox_range": [95,100]
          },{
            "start_time": "05:00:00-05:00",
            "sleep_length": 60,
            "temp_range": [76, 80],
            "hr_range": [90,100],
            "movement_range": [0,5],
            "ox_range": [95,100]
          }
        ]
      }
    }`

  const [dataTemplate, setDataTemplate] = useState(defaultTemplate)

  const onGenerateClick = async () => {
    const generateSevenDaysDataResult = await generateSevenDaysData(accountId, deviceId, JSON.parse(dataTemplate))

    if (generateSevenDaysDataResult) {
      snackbar.openSnackbar(
        `Generate 7 days data request sent successfully. Please wait a few minutes for sleep data to appear in app.`
      )
    } else {
      snackbar.openSnackbar(
        'Error sending generate 7 days request. Please try again.'
      )
    }

    setIsButtonDisabled(true)
  }

  const onDataTemplateChange = (value) => {
    setDataTemplate(value)
    setIsButtonDisabled(false)

    try{
      JSON.parse(dataTemplate)
    }
    catch (e) {
      snackbar.openSnackbar(
        'Error parsing template, please verify it is a valid JSON.'
      )
    }

  }

  return (
    <div>
      <textarea
        rows={50}
        cols={70}
        onChange={ (e) => onDataTemplateChange(e.target.value)}
        value={dataTemplate}
        style={{padding: 10}}
      />
      <Button
        className="emptyBtn emptySmaller"
        disabled={isButtonDisabled}
        variant="outlined"
        onClick={onGenerateClick}>
        Generate 7 days Data
      </Button>
    </div>
  )
}


GenerateSevenDaysData.propTypes = {
  authUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.string
    ),
    roles: PropTypes.shape(PropTypes.string),
  }),
  currentDevice: PropTypes.shape({
    accountId: PropTypes.string,
    deviceId: PropTypes.string,
    dsn: PropTypes.string,
    type: PropTypes.string,
  }),
}

GenerateSevenDaysData.defaultProps = {
  authUser: {},
  currentDevice: {},
}

export default GenerateSevenDaysData
