import { useContext } from 'react'
import FirebaseContext, { withFirebase } from './context'
import Firebase from './firebase'


export default Firebase

export { FirebaseContext, withFirebase }

export const useFirebase = () => useContext(FirebaseContext) 
