import camelcase from 'camelcase'

import API from '../../axiosInstance'


const accountsAPI = {
    getAccountByParam: async (queryParam, values) => {
        try {
            let config = {}
            if (queryParam === 'firstLastName') {
                config = {
                    headers: {},
                    params: {
                        query: '"firstName=urn:request:headers.OWLT-QUERY-FIRSTNAME,lastName=urn:request:headers.OWLT-QUERY-LASTNAME"'
                    }
                }
                const [firstName, lastName] = values
                config.headers['OWLT-QUERY-FIRSTNAME'] = firstName
                config.headers['OWLT-QUERY-LASTNAME'] = lastName
            } else {
                const queryHeader = `OWLT-QUERY-${camelcase(queryParam).toUpperCase()}`
                config = {
                    headers: {},
                    params: {
                        query: `"${queryParam}=urn:request:headers.${queryHeader}"`
                    }
                }
                const [value] = values
                config.headers[queryHeader] = value
            }
            const result = await API().get('/accounts', config)
            return result.data
        } catch (error) {
            console.error(error)
            return null
        }
    },

    getAccountByAccountKey: async (accountId) => {
        try {
            const result = await API().get(`/accounts/${accountId}`) // returns 501 not implemented
            return result.data
        } catch (error) {
            console.error(error)
            return null
        }
    },

    getSubscriptionByAccountKey: async (accountId) => {
        try{
            const result = await API().get(`/accounts/${accountId}/subscription`)
            return result.data
        }catch (error) {
            console.error(error)
            return null
        }

    }
    // TODO: These endpoints are to be used in later iterations of the backend

    // createAccount: async () => {
    //     try {
    //         const result = await API().post('/accounts')
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, accountData) // returns 501 not implemented
    //     }
    //     return {}
    // },

    // resetAccountPassword: async (accountKey, resetPassword) => {
    //     try {
    //         const result = await API().post(`/accounts/${accountKey}:${resetPassword}`) // returns 501 not implemented
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, accountData)
    //     }
    //     return {}
    // },

    // revokeAccountTokens: async (accountKey, revokeTokens) => {
    //     try {
    //         const result = await API().post(`/accounts/${accountKey}:${revokeTokens}`) // returns 501 not implemented
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, accountData)
    //     }
    //     return {}
    // },
}

export default accountsAPI
