import PropTypes from 'prop-types'
import React from 'react'

import { withFirebase } from '../../Firebase'

import SocialLoginToggle from './SocialLoginToggle'


const LoginManagementBase = ({ onUnlink, onLink, SIGN_IN_METHODS, activeSignInMethods, error }) => {
  return (
    <div>
      <ul>
        {SIGN_IN_METHODS.map(signInMethod => {
          const onlyOneLeft = activeSignInMethods.length === 1
          const isEnabled = activeSignInMethods.includes(signInMethod.id)
          return (
            <div key={signInMethod.id} className="loginToggle">
              <SocialLoginToggle
                isEnabled={isEnabled}
                onlyOneLeft={onlyOneLeft}
                signInMethod={signInMethod}
                onUnlink={onUnlink}
                onLink={onLink}
              />
            </div>
          )
        })}
      </ul>
      {error && <p className="errorMessage">{error.message}</p>}
    </div>
  )
}

LoginManagementBase.propTypes = {
  SIGN_IN_METHODS: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    provider: PropTypes.string
  })).isRequired,
  activeSignInMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  }),
  onUnlink: PropTypes.func.isRequired,
  onLink: PropTypes.func.isRequired
}

LoginManagementBase.defaultProps = {
  error: { code: "None", message: "No Error" },
}

const LoginManagement = withFirebase(LoginManagementBase)

export default LoginManagement
