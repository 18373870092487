import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { SnackbarContext } from '../common/Snackbar'
import { withFirebase } from '../Firebase'

import PasswordForget from './PasswordForget'


const PasswordForgetContainer = ({ firebase }) => {
  const [error, setError] = useState(null)
  const snackbar = useContext(SnackbarContext)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = async data => {
    firebase
      .doPasswordReset(data.email)
      .then(() => {
        setError(null)
        snackbar.openSnackbar('Email sent!')
      })
      .catch(err => {
        console.error(`Error sending email: ${err}`)
        setError({ err })
        snackbar.openSnackbar(
          'An error occured while sending email. Please try again.',
        )
      })
  }

  return (
    <PasswordForget
      onSubmit={handleSubmit(onSubmit)}
      register={register}
      isSubmitting={isSubmitting}
      error={error}
    />
  )
}

PasswordForgetContainer.propTypes = {
  firebase: PropTypes.shape({
    doPasswordReset: PropTypes.func
  })
}

PasswordForgetContainer.defaultProps = {
  firebase: {
    doPasswordReset: () => { }
  }
}

export default withFirebase(PasswordForgetContainer)
