import {
  Box,
  Grid,
  IconButton,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TableContainer,
  TablePagination,
  Paper } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import {
  FirstPage,
  LastPage,
  KeyboardArrowRight,
  KeyboardArrowLeft } from '@material-ui/icons'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import '../../../index.css'
import { getNotificationsHistory } from '../../../api/lib/apiV1'

import {
  deviceTypeSock3Sleep,
  deviceTypeSock3DreamSamd,
  deviceTypeSock4,
} from '../../../constants/deviceConstants'

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />} </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const getNotificationStatusLabel = (statusId) => {
  switch (statusId) {
    case 0:
      return 'Unspecified'
    case 1:
      return 'Pending'
    case 2:
      return 'Success'
    case 3:
      return 'Failure'
    default:
      return 'Unknown'
  }
}

const getEventTypeLabel = (eventTypeId) => {
  switch (eventTypeId) {
    case 1000:
      return 'Unspecified'
    case 1001:
      return 'Sock high heart rate'
    case 1002:
      return 'Sock low battery'
    case 1003:
      return 'Sock low heart rate'
    case 1004:
      return 'Sock low integer read'
    case 1005:
      return 'Sock low oxygen'
    case 1006:
      return 'Sock disconnected'
    case 1007:
      return 'Sock off'
    case 1008:
      return 'Sock discomfort'
    case 1009:
      return 'Sock high oxygen'
    case 1010:
      return 'Sock lost power'
    case 1011:
      return 'Sock sleep interval'
    case 1012:
      return 'Sock sleep start'
    case 1013:
      return 'Sock sleep end'
    case 1014:
      return 'Sock awake'
    case 1015:
      return 'Sock self soothe'
    case 1016:
      return 'Sock monitoring on'
    case 1017:
      return 'Sock monitoring off'
    case 1018:
      return 'Sock recovery mode disabled'
    case 2000:
      return 'Camera unspecified'
    case 2001:
      return 'Camera sound'
    case 2002:
      return 'Camera motion'
    case 2003:
      return 'Camera temperature high'
    case 2004:
      return 'Camera temperature low'
    case 2005:
      return 'Camera humidity high'
    case 2006:
      return 'Camera humidity low'
    case 2007:
      return 'Camera crying'
    case 3000:
      return 'Profile unspecified'
    case 3001:
      return 'Profile sleep'
    default:
      return 'Unknown'
  }
}

const NotificationsHistory = ({
  accountId,
  deviceId,
  deviceType,
}) => {
  const [page, setPage] = useState(0)
  const [data, setData] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    switch (deviceType) {
      case deviceTypeSock3Sleep.type:
      case deviceTypeSock3DreamSamd.type:
      case deviceTypeSock4.type:
        getNotificationsHistory(accountId, deviceId)
          .then((response) => {
            setData(response)
          })
          .catch((error) => console.error('Error fetching data:', error));
        break
      default:
        break
    }
  }, [accountId, deviceId, deviceType]);

  const header =  (
    <Grid item>
      <Paper elevation={0} className='sectionTitleContainer'>
        <h1 className='sectionTitle'>Notifications History</h1>
      </Paper>
    </Grid>
  )

  if (!Array.isArray(data) || !data.length) {
    return (
      <Grid container direction='column' justContent='flex-start'>
        {header}
        <Grid container direction='row'>
          <Grid className='gridItem' item xs={4}>Device is ineligible for internal notifications or has not produced any.</Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction='column' justContent='flex-start'>
      {header}
      <Grid container direction='row'>
        <Grid className='gridItem' item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Type</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Client ID</TableCell>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map(item => (
            <TableRow>
              <TableCell>{getEventTypeLabel(item.ServiceEventTypeId)}</TableCell>
              <TableCell>{item.CreateTime ? format(new Date(item.CreateTime), 'LLL dd, yyyy, h:mm:ss aa') : 'Unknown'}</TableCell>
              <TableCell>{getNotificationStatusLabel(item.NotificationStatusId)}</TableCell>
              <TableCell>{item.ClientId}</TableCell>
            </TableRow>
            ))}
            {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={4} />
            </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}

NotificationsHistory.propTypes = {
  accountId: PropTypes.string,
  deviceId: PropTypes.string,
  deviceType: PropTypes.string,
}

NotificationsHistory.defaultProps = {
  accountId: "",
  deviceId: "",
  deviceType: "",
}

export default NotificationsHistory
